import { get } from 'lodash'

import {
    CHILD_REGISTERED,
    ANNOUNCE_REGISTERING_CHILD,
    ANNOUNCE_CHILD_NOT_REGISTERED,

    CHILD_DEREGISTERED,
    ANNOUNCE_DEREGISTERING_CHILD,
    ANNOUNCE_CHILD_NOT_DEREGISTERED,

    CHILD_INFORMATION,
    ANNOUNCE_RETRIEVING_CHILD_INFORMATION,
    ANNOUNCE_CHILD_INFORMATION_NOT_RETRIEVED,

    MOST_RECENT_ARMBAND_SCAN,
    ANNOUNCE_RETRIEVING_MOST_RECENT_ARMBAND_SCAN,
    ANNOUNCE_MOST_RECENT_ARMBAND_SCAN_NOT_RETRIEVED
} from '../actions/GameServer'


const initialState = {
    childRegistered: false,
    registeringChild: false,
    childDeregistered: false,
    deregisteringChild: false,
    child_information: null,
    childInfoRetrieved: false,
    recentArmbandScan: null,
    response: null,
    deregistrationResponse: null,
    childInformationResponse: null,
    recentArmbandScanResponse: null,
    armbandCodeRetrieved: false,
    GAME_SERVER_API_BASE: get(window, 'LOCAL_SETTINGS.GAME_SERVER_API_BASE', '/')
}

export default function game_server(state = initialState, action) {
    switch (action.type) {
        case CHILD_REGISTERED:
            return Object.assign({}, state, {
                childRegistered: true,
                response: action.response,
                childInfoRetrieved: false,
                childDeregistered: false,
                armbandCodeRetrieved: false
            })
        case ANNOUNCE_REGISTERING_CHILD:
            return Object.assign({}, state, {registeringChild: true})
        case ANNOUNCE_CHILD_NOT_REGISTERED:
            return Object.assign({}, state, {childRegistered: false})
        case CHILD_DEREGISTERED:
            return Object.assign({}, state, {
                childDeregistered: true,
                deregistrationResponse: action.deregistrationResponse,
                childInfoRetrieved: false,
                childRegistered: false,
                armbandCodeRetrieved: false
            })
        case ANNOUNCE_DEREGISTERING_CHILD:
            return Object.assign({}, state, {deregisteringChild: true})
        case ANNOUNCE_CHILD_NOT_DEREGISTERED:
            return Object.assign({}, state, {childDeregistered: false, })
        case CHILD_INFORMATION:
            return Object.assign({}, state, {
                childInformation: action.child_information,
                childInfoRetrieved: true,
                childInformationResponse: action.childInformationResponse,
                childRegistered: false,
                childDeregistered: false,
                armbandCodeRetrieved: false
            })
        case ANNOUNCE_RETRIEVING_CHILD_INFORMATION:
            return Object.assign({}, state, {retrievingChildInfo: true})
        case ANNOUNCE_CHILD_INFORMATION_NOT_RETRIEVED:
            return Object.assign({}, state, {
                childInformation: null,
                childInfoRetrieved: false
            })
        case MOST_RECENT_ARMBAND_SCAN:
            return Object.assign({}, state, {
                recentArmbandScan: action.recentArmbandScan,
                armbandCodeRetrieved: true,
                recentArmbandScanResponse: action.recentArmbandScanResponse,
                childRegistered: false,
                childDeregistered: false,
                childInfoRetrieved: false,
            })
        case ANNOUNCE_RETRIEVING_MOST_RECENT_ARMBAND_SCAN:
            return Object.assign({}, state, {retrievingArmbandCode: true})
        case ANNOUNCE_MOST_RECENT_ARMBAND_SCAN_NOT_RETRIEVED:
            return Object.assign({}, state, {armband_code: null, armband_code_retrieved: false})
        default:
            return state
    }
}
