import { ENTITY_KEY__TICKET } from '../actions/ItemListKeyRegistry'

import {
    invalidateAllItems,
    invalidateItems,
    fetchItemsIfNeeded,
    ensureItemsLoaded,
    getItem,
    getItems,
    getItemsById,
    isItemInvalidated,
    itemPost
} from './Item'
import { invalidateVisits, getVisit } from './Visit'
import {
    registerChildOnGameServerFromVisit
} from './GameServer'

export function invalidateAllTickets() {
    return (dispatch, getState) => {
        dispatch(invalidateAllItems(ENTITY_KEY__TICKET))
    }
}

export function invalidateTickets(ticket_ids_to_invalidate) {
    return (dispatch, getState) => {
        dispatch(invalidateItems(ENTITY_KEY__TICKET,
                                 ticket_ids_to_invalidate
        ))
    }
}

export function fetchTicketsIfNeeded(list_key) {
    return (dispatch, getState) => {
        dispatch(fetchItemsIfNeeded(ENTITY_KEY__TICKET, list_key))
    }
}

export function ensureTicketsLoaded(ticket_ids) {
    return ensureItemsLoaded(ENTITY_KEY__TICKET, ticket_ids)
}

export function getTicket(state, ticket_id) {
    return getItem(state, ENTITY_KEY__TICKET, ticket_id)
}

export function getTickets(state, ticket_ids) {
    return getItems(state, ENTITY_KEY__TICKET, ticket_ids) || {}
}

export function getTicketsById(state, ticket_ids) {
    return getItemsById(state, ENTITY_KEY__TICKET, ticket_ids)
}

export function isTicketInvalidated(state, ticket_id) {
    return isItemInvalidated(ENTITY_KEY__TICKET, state, ticket_id)
}

export function startTicketRegistration(ticket_nr, armband_number, updated_ticket_values, onRegistrationStarted) {
    const url =  "register/" + ticket_nr +  "/startTicketRegistration/"
    const field_name = "ticket_nr"
    const field_value = ticket_nr
    const method = "POST"
    const data = { ticket_nr: ticket_nr,
                   armband_number: armband_number,
                   updated_ticket_values: updated_ticket_values }

    const on_done = function(json, dispatch) {
        const { payload } = json
        dispatch(invalidateTickets([payload.ticket_id]))
        onRegistrationStarted(payload)
    }
    
    return itemPost(ENTITY_KEY__TICKET, [ticket_nr], url, field_name, field_value, method, data, on_done)
}

export function finaliseTicketRegistration(visit_id, onRegistrationFinalised, onRegistrationFailed, args ) {
    return (dispatch, getState) => {
        const state = getState()
        const visit = getVisit(state, visit_id)
        const { force_new_child } = args || {}
        const url =  "register/" + visit.ticket_nr +  "/finaliseTicketRegistration/"
        const field_name = "ticket_nr"
        const field_value = visit.ticket_nr
        const method = "POST"
        const data = { ticket_nr: visit.ticket_nr,
                       force_new_child }

        const on_done = function(json, dispatch) {
            const { payload } = json
            const visit_from_server = payload.visit
    
            const on_ok = function() {
                onRegistrationFinalised(visit_from_server)
            }

            const on_fail = function(json) {
                onRegistrationFailed(json)
            }
            
            dispatch(invalidateTickets([payload.ticket_id]))
            dispatch(invalidateVisits([payload.visit_id]))
            dispatch(registerChildOnGameServerFromVisit(visit_from_server, on_ok, on_fail))
        }
        
        dispatch(itemPost(ENTITY_KEY__TICKET, [visit.ticket_nr], url, field_name, field_value, method, data, on_done))
    }
}

export function flattenTicket(ticket) {
    if (! ticket ) {
        return {}
    }
   return {
        ticket_nr: ticket.ticket_nr
    }
}
