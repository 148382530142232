/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'

class DefaultCard extends Component {
    render() {
        const {
            extra_class,
            component_child_class
        } = this.props;
        
        return (
            <div css={[
                card,
                extra_class,
                component_child_class
            ]}>
              {this.props.children}
            </div>
        )
    }
}
export default DefaultCard

const card = css`
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.23);
background-color: ${theme.colors.white};
}
`
