/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'

import MainLayout from './MainLayout'
import CardRoundedBottom from './CardRoundedBottom'
import CardHeaderDark from './CardHeaderDark'
import CardTextLarge from './CardTextLarge'
import CardPrimaryButton from './CardPrimaryButton'
import CardSecondaryButton from './CardSecondaryButton'

class CustomerRegistrationRevisitConfirm extends Component {
    onClickContinue = () => {
        const { history } = this.props;
        history.push('/registration-complete')
    }

    render() {
        const { isLoading } = this.props;
        
        return (
            <MainLayout>
              <div css={main}>
                <CardRoundedBottom extra_class={card}>
                  <CardHeaderDark label="Register new guest" />
                  {!isLoading &&
                   <div css={body}>
                     <div css={text_body}>
                       <p>
                         <CardTextLarge extra_class={single_line} label="Thank you, we will link your previous visit with this" />
                         <CardTextLarge extra_class={single_line} label="armband and you will keep playing." />
                       </p>
                     </div>

                     <div css={footer}>
                       <CardPrimaryButton
                           type="button"
                           label="I changed my mind"
                           custom_styles={extra_btn_styles}
                           to="/arriving/revisit"
                       /> 
                       <CardSecondaryButton
                           type="button"
                           label="Continue"
                           onClickButton={this.onClickContinue}
                           custom_styles={extra_btn_styles}/>
                     </div>
                   </div>
                  }

                  {isLoading &&
                   <div css={loader}>
                     <CardTextLarge label="Loading registration ..." />
                   </div>
                  }
                </CardRoundedBottom>
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state) {
    return {
        isLoading: false
    }
}
export default connect(mapStateToProps)(CustomerRegistrationRevisitConfirm)

const main = css`
  display: flex; 
 flex: 1;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`

const card = css`
display: flex;
flex: 1;
flex-direction: column;
`

const body = css`
padding: 0 40px 40px 40px;
padding: 80px 110px;

p {
  padding: 0;
  margin: 0 0 40px 0;
}
`

const text_body = css`
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const single_line = css`
display: block;
text-align: center;
line-height: 40px;
`

const footer = css`
display: flex;
justify-content: space-between;
`

const loader = css`
 height: 600px;
 display: flex;
 align-items: center;
 justify-content: center;
 text-align: center;
`

const extra_btn_styles = {
    width: '270px',
    padding: '17px',
    fontSize: '18px',
    height: '55px',
    marginRight: '10px',
    fontWeight: 400,
    textAlign: 'center'
}
