import { ENTITY_KEY__VISIT } from '../actions/ItemListKeyRegistry'
import { get } from 'lodash'
import {
    invalidateAllItems,
    invalidateItems,
    fetchItemsIfNeeded,
    ensureItemsLoaded,
    getItem,
    getItems,
    getItemsById,
    isItemInvalidated,
    updateItem,
    itemPost
} from '../actions/Item'
import { getListFilter } from './ItemList'
import { deregisterChildFromGameServer,
         getChildInformationFromGameServer } from './GameServer'
import { download } from './lib'

export function invalidateAllVisits() {
    return (dispatch, getState) => {
        dispatch(invalidateAllItems(ENTITY_KEY__VISIT))
    }
}

export function invalidateVisits(visit_ids_to_invalidate) {
    return (dispatch, getState) => {
        dispatch(invalidateItems(ENTITY_KEY__VISIT,
                                 visit_ids_to_invalidate
        ))
    }
}

export function fetchVisitsIfNeeded(list_key) {
    return (dispatch, getState) => {
        dispatch(fetchItemsIfNeeded(ENTITY_KEY__VISIT, list_key))
    }
}

export function ensureVisitsLoaded(visit_ids) {
    return ensureItemsLoaded(ENTITY_KEY__VISIT, visit_ids)
}

export function getVisit(state, visit_id) {
    return getItem(state, ENTITY_KEY__VISIT, visit_id)
}

export function getVisits(state, visit_ids) {
    return getItems(state, ENTITY_KEY__VISIT, visit_ids) || {}
}

export function getVisitsById(state, visit_ids) {
    return getItemsById(state, ENTITY_KEY__VISIT, visit_ids)
}

export function isVisitInvalidated(state, visit_id) {
    return isItemInvalidated(ENTITY_KEY__VISIT, state, visit_id)
}

export function updateVisit(visit_id, new_values, on_done) {
    return updateItem(ENTITY_KEY__VISIT, [visit_id], '__all__', '__all__', on_done, new_values)
}

export function endVisit(visit, onLeftDone, onLeftFail) {
    const url =  "vregister/" + visit.id +  "/endVisit/"
    const field_name = "visit_id"
    const field_value = visit.id
    const method = "POST"
    const data = {}

    const on_done = function(json, dispatch) {
        const { payload } = json
        dispatch(invalidateVisits([payload.visit_id]))
        dispatch(deregisterChildFromGameServer(visit.child.ref, onLeftDone, onLeftFail))

        const onGotChildInformation = function(payload) {
            dispatch(sendGameServerChildInformationToServer(visit.id, payload, onLeftDone, onLeftFail))
        }
        dispatch(getChildInformationFromGameServer(visit.child.ref, onGotChildInformation, onLeftFail))
    }
    
    return itemPost(ENTITY_KEY__VISIT, [visit.id], url, field_name, field_value, method, data, on_done)
}

function sendGameServerChildInformationToServer(visit_id, child_info, on_ok, on_fail) {

    const url =  "vregister/" + visit_id +  "/updateChildDataFromGameServer/"
    const field_name = "visit_id"
    const field_value = visit_id
    const method = "PUT"
    const data = child_info
    
    return itemPost(ENTITY_KEY__VISIT, [visit_id], url, field_name, field_value, method, data, on_ok)
}

export function resendCertificateEmail(visit_id, on_ok) {

    const url =  "vregister/" + visit_id +  "/resendVisitEmail/"
    const field_name = "visit_id"
    const field_value = visit_id
    const method = "POST"
    const data = {}
    
    return itemPost(ENTITY_KEY__VISIT, [visit_id], url, field_name, field_value, method, data, on_ok)
}

export function flattenVisit(visit) {
    if (! visit ) {
        return {}
    }
    return {
        armband_number: visit.armband_number,
        status: visit.status,
        child_name: visit.child.name,
        child_surname: visit.child.surname,
        child_date_of_birth: visit.child.birth_date,
        guardian_name: get(visit, ["guardian", "name"]),
        guardian_surname: get(visit, ["guardian", "surname"]),
        guardian_email_address: get(visit, ["guardian", "email_address"]),
        guardian_contact_number_1: get(visit, ["guardian", "contact_number_1"]),
        guardian_contact_number_2: get(visit, ["guardian", "contact_number_2"]),
        collector_name: get(visit, ["collector", "name"]),
        collector_surname: get(visit, ["collector", "surname"]),
        collector_email_address: get(visit, ["collector", "email_address"]),
        collector_contact_number_1: get(visit, ["collector", "contact_number_1"]),
        collector_contact_number_2: get(visit, ["collector", "contact_number_2"]),
    }
}

export function downloadVisitsCsv(list_key) {
    return (dispatch, getState) => {
        const state = getState()
        const filter = getListFilter(state, list_key)
        const url = 'visit/as_csv/'
        return download(state, url, {}, filter)
    }
}

