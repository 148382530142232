/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'

class Loading extends Component {
    render() {
        const {extra_class} = this.props;
        
        return (
            <div css={[loading, extra_class]}>
              Loading
            </div>
        )
    }
}
export default Loading

const loading = css`
  height: 50px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
`
