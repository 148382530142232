/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../emotion/theme'
import DefaultButton from './DefaultButton'

class CardPrimaryButton extends Component {
    render() {
        return (
            <DefaultButton
                extra_class={btn}
                {...this.props} />
        )
    }
}
export default CardPrimaryButton

const btn = css`
background-color: ${theme.colors.button_gray};
color: ${theme.colors.dark_gray};
border-radius: 5px;
padding: 14px;
min-width: 150px;

&:hover {
   background-color: ${theme.colors.hover_gray};
}
}
`
