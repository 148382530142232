/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../emotion/theme'
import { withRouter } from 'react-router'
import { getCurrentPath } from '../actions/helpers'
import { clearAuthentication } from '../actions/Auth'

import logo from '../images/logo_small.png'
import MainHeaderPrimaryButton from './MainHeaderPrimaryButton' 
import MainHeaderSecondaryButton from './MainHeaderSecondaryButton'

class MainHeader extends Component {
    onClickLogout = () => {
        const { history, dispatch } = this.props
        dispatch(clearAuthentication())
        history.push('/')
    }

    render() {
        const { isHome } = this.props;
        
        return (
            <div css={ main }>
              <div css={container}>
                <div css={left_col}>
                  { !isHome &&
                    <MainHeaderPrimaryButton
                        label="Home"
                        to="/home"
                        onClickButton={this.onClickHome} />
                  }
                </div>

                <div css={middle_col}>
                  <img css={logo_style} src={logo} alt="" />
                </div>
                
                <div css={right_col}>
                  <MainHeaderSecondaryButton
                      label="Logout"
                      type="button"
                      onClickButton={this.onClickLogout}
                  />
                </div>
              </div>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    const current_path = getCurrentPath(props.history);
    
    return {
        isHome: current_path === '/home'
    }
}
export default withRouter(connect(mapStateToProps)(MainHeader))

const main = css`
height: 100px;
background-color: ${theme.colors.white};
border-bottom: 1px solid ${theme.colors.border_gray};
`

const container = css`
display: flex;
align-items: center;
flex: 1;
max-width: 1600px;
height: 100%;
width: 100%;
margin: 0 auto;
padding: 0 16px;
`

const left_col = css`
  display: flex;
  align-items: center;
  flex: 1;
`

const middle_col = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

const right_col = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`

const logo_style = css`
  height: 33px;
`
