/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../emotion/theme'
import DefaultButton from './DefaultButton'

class LoginButton extends Component {
    render() {        
        return (
            <DefaultButton
                extra_class={btn}
                {...this.props}/>
        )
    }
}
export default LoginButton

const btn = css`
background-color: ${theme.colors.button_green};
color: ${theme.colors.white};
border-radius: 50px;
padding: 0 14px;
min-width: 200px;
font-weight: 500;
height: 50px;
display: flex;
align-items: center;
justify-content: center;
font-size: 17px;

&:hover, &:active {
background-color: ${theme.colors.hover_green};
}
`
