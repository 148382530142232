/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import onClickOutside from 'react-onclickoutside'

import DropdownSelectItem from './DropdownSelectItem'
import { default_theme as theme } from '../emotion/theme'
import arrow_icon from '../images/arrow_down.png'
import {getCustomerStatusDisplayName} from '../actions/Customer'

class DropdownSelectField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDropdown: false,
            inputDisplayName: ''
        }
    }

    componentDidMount() {
        const { input } = this.props;
        this.setDisplayName(input.value)
    }

    componentDidUpdate(prev_props) {
        const { input } = this.props;
        this.setDisplayName(input.value)
    }
    
    onSelectOption = (value) => {
        const { input: { onChange } } = this.props
        onChange(value)
        this.setDisplayName(value);
        this.closeDropdown();
    }

    handleClickOutside = () => {
        this.closeDropdown()
    }

    closeDropdown = () => {
        this.setState({showDropdown: false});
    }

    openDropdown = () => {
        this.setState({showDropdown: true});
    }

    setDisplayName = (value) => {
        const displayName = getCustomerStatusDisplayName(value);
        const { inputDisplayName } = this.state
        if ( inputDisplayName !== displayName ) {
            this.setState({inputDisplayName: displayName})
        }
    }
    
    render() {
        const { form_input_styles, selectable_options, input, extra_dropdown_class } = this.props
        const { showDropdown, inputDisplayName } = this.state;
        
        return (          
            <div onClick={this.openDropdown} css={[input_styles, form_input_styles]}>
              <div css={input_content}>
                <span>{inputDisplayName}</span>
                <img src={arrow_icon} alt="" />
              </div>
              {showDropdown &&
               <div css={[dropdown, extra_dropdown_class]}>                
                 <div css={dropdown_wrapper}>
                   {selectable_options.map((option, index) => {
                        if(option.name !== input.value) {
                            return (
                                <DropdownSelectItem
                                    key={index}
                                    label={option.label}
                                    value={option.name}
                                    on_select={this.onSelectOption} />
                            )
                        } else {
                            return null
                        }
                   })}
                 </div>
               </div>
              }
            </div>
        )
}
}

export default onClickOutside(DropdownSelectField);

const input_styles = css`
 position: relative;
border: 1px solid ${theme.colors.border_gray};
border-radius: 5px;
height: 40px;
display: flex;
align-items: center;
font-size: 17px;
padding: 0 8px 0 8px;
outline: none;
width: 100%;
color: ${theme.colors.text_gray_dark};
font-weight: 500;
background-color: ${theme.colors.white};

&:focus, &:active {
border: 1px solid ${theme.colors.gray4};
}
`
const input_content = css`
  display: flex;
  flex: 1;
  align-items: center;
  
  span {
   flex: 1;
  }

  img {
   width: 18px;
  }
`

const dropdown = css`
 position: absolute;
 top: 37px;
 left: -1px;
z-index: 2;
width: 100%;
`

const dropdown_wrapper = css`
display: flex;
flex-direction: column;
border: 1px solid ${theme.colors.border_gray};
background-color: ${theme.colors.white};
border-bottom: 0;
z-index: 2;
`
