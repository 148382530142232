import { LOADING } from '../actions/Loading'

const initialState = {
    status: false
}

export default function loading(state = initialState, action) {
    switch (action.type) {
        case LOADING:
            return Object.assign({}, state, {status: action.status})
                                 
        default:
            return state
    }
    
}
