/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'

import InputField from './InputField'
import { default_theme as theme } from '../emotion/theme'
import LoginButton from './LoginButton'
const required = value => (value || typeof value === 'number' ? undefined : 'Required')

class LoginForm extends Component {
    render() {
        const { handleSubmit, error, valid } = this.props

        return (
            <form css={ form_layout } onSubmit={handleSubmit}>
              <div css={ error_container }>
                { error && <p>{ error }</p>}
              </div>
              
              <div css={ field_container }>
                <Field
                    autoFocus
                    autoComplete="off"
                    type="text"
                    name="email"
                    validate={[required]}
                    placeholder="Username"
                    component={ InputField }
                    extra_class={ form_input } />
              </div>
              <div css={ field_container }>
                <Field
                    type="password"
                    validate={[required]}
                    name="password"
                    placeholder="Password"
                    component={ InputField }
                    extra_class={ form_input } />
              </div>
              <div css={ field_container }>
                <div css={ footer }>
                  { valid &&
                    <LoginButton
                        label="Login"
                        type="submit"
                    />
                  }
                  { !valid &&
                    <LoginButton
                        disabled={ true }
                        type="button"
                        label="Login"
                    />
                  }
                </div>
              </div>
            </form>
        )
    }
}
LoginForm = reduxForm({
    form: 'LOGIN_FORM'
})(LoginForm);
function mapStateToProps(state) {
    return {}
}
export default withRouter(connect(mapStateToProps)(LoginForm))

const form_layout = css`
width: 100%;
margin: 0; 
padding: 0;
`

const field_container = css`
margin-bottom: 18px;
`

const error_container = css`
display: flex;
justify-content: flex-start;
align-items: center;
height: 100px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
justify-content: flex-end;
align-items: center;
`

const form_input = css`
height: 60px;
font-size: 17px;
font-weight: 500;
${theme.colors.gray_dark}
`
