/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form';
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { isLoggedIn } from '../actions/Auth'
import MainLayout from './MainLayout'
import InputField from './InputField'
import CardRoundedBottom from './CardRoundedBottom'
import CardHeaderDark from './CardHeaderDark'
import CardSecondaryButton from './CardSecondaryButton'
import { default_theme as theme } from '../emotion/theme'

import { setArmbandScannerNumber } from '../actions/settings'

class ArmbandConfiguration extends Component {

    onArmbandNumberSet = (evt) => {
        const { history, form_values, is_logged_in } = this.props
        evt.preventDefault()
        if ( is_logged_in ) {
            setArmbandScannerNumber(form_values.armband_scanner_number)
            history.push("/home")
        }
    }

    render() {
        return (
            <MainLayout>
              <div css={main}>
                <CardRoundedBottom extra_class={card}>
                <CardHeaderDark label="Armband configuration" />
                <p>
                  Enter the unique armband scanner number for this terminal.
                </p>
                <p>
                  If this computer will not be used for scanning armbands, enter NA
                </p>
                <form onSubmit={this.onArmbandNumberSet}>
                  <p>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="armband_scanner_number"
                        type="text"/>
                  </p>
                  <div css={submit_container}>
                    <CardSecondaryButton
                        custom_styles={btn}
                        type="submit"
                        label="Save" />
                  </div>
                </form>
                </CardRoundedBottom>
              </div>
            </MainLayout>
        )
    }
}

ArmbandConfiguration = reduxForm({
    form: 'ARMBAND_CONFIGURATION_FORM'
})(ArmbandConfiguration);
function mapStateToProps(state, props) {

    return {
        form_values: getFormValues('ARMBAND_CONFIGURATION_FORM')(state),
        is_logged_in: isLoggedIn(state)
    }
}
export default withRouter(connect(mapStateToProps)(ArmbandConfiguration))

const main = css`
  display: flex; 
 flex: 1;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`

const card = css`
display: flex;
flex: 1;
flex-direction: column;
`

const submit_container = css`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
`

const btn = css`
background-color: ${theme.colors.button_green};
color: ${theme.colors.white};
border-radius: 50px;
padding: 0 14px;
min-width: 200px;
font-weight: 500;
height: 50px;
display: flex;
align-items: center;
justify-content: center;
font-size: 17px;

&:hover, &:active {
background-color: ${theme.colors.hover_green};
}
`
