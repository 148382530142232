/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { map } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import arrow_down from '../images/arrow_down.png'
import DefaultIconButton from './DefaultIconButton'
import VisitTableRow from './VisitTableRow'

class CustomerTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            header_sizes: []
        }
    }

    onSortName = () => {
        const {onSortColumn} = this.props;
        onSortColumn('first_name')
    }

    onSortSurname = () => {
        const {onSortColumn} = this.props;
        onSortColumn('surname')
    }

    onSortGuardian = () => {
        const {onSortColumn} = this.props;
        onSortColumn('guardian_name')
    }

    onSortExitTime = () => {
        const {onSortColumn} = this.props;
        onSortColumn('exit_time')
    }

    onClickDelete = (visit_id) => {
        const { onClickDelete } = this.props;
        onClickDelete(visit_id)
    }

    onClickVisit = (visit_id) => {
        const { onClickVisit } = this.props;
        onClickVisit(visit_id)
    }

    render() {
        const {visits} = this.props;
        
        return (
            <div css={ table }>
              <div css={ thead }>
                <div css={ tr }>
                  <div css={ th }>
                    <span>Name</span>
                    { false && <DefaultIconButton
                        extra_class={sort_btn}
                        type="button"
                        onClickButton={this.onSortName}
                                   icon_src={arrow_down}
                    /> }
                  </div>
                  <div css={ th }>
                    <span>Surname</span>
                    { false && 
                    <DefaultIconButton
                        extra_class={sort_btn}
                        type="button"
                        onClickButton={this.onSortSurname}
                        icon_src={arrow_down}
                    /> }
                  </div>
                  <div css={ th }>
                    <span>Guardian</span>
                    { false && <DefaultIconButton
                        extra_class={sort_btn}
                        type="button"
                        onClickButton={this.onSortGuardian}
                        icon_src={arrow_down}
                    /> }
                  </div>
                  <div css={ [th, second_last_th] }>
                    <span>Exit time</span>
                    { false && <DefaultIconButton
                        extra_class={sort_btn}
                        type="button"
                        onClickButton={this.onSortExitTime}
                        icon_src={arrow_down}
                    /> }
                  </div>
                </div>
              </div>
              <div css={ tbody }>
                {map(visits, (visit, row_index) => {
                     return (
                         <VisitTableRow
                             key={ `row__` + row_index }
                             visit={visit}
                             onClickVisit={this.onClickVisit}
                             onClickDelete={this.onClickDelete}
                         />
                     )
                })}
              </div>
            </div>
        )
    }
}
export default CustomerTable

const table = css`
display: flex;
flex-direction: column;
flex: 1;
font-size: 14px;
border-top: 1px solid ${theme.colors.border_gray};
overflow-y: scroll;
`

const thead = css`
font-weight: 500;
color: ${theme.colors.dark_gray};
font-size: 16px;
background-color: ${theme.colors.table_header_gray};

div { 
padding-top: 0;
padding-bottom: 0;
   &:hover {
    background-color: transparent;
  }
}

}
`

const th = css`
display: flex;
flex: 2;
align-items: center;
height: 51px;
padding: 10px;
border-right: 1px solid ${theme.colors.border_gray};

span {
  flex: 1;
}
`

const second_last_th = css`
margin-right: 50px;
`

const tbody = css`
display: flex;
flex: 1;
flex-direction: column;
`

const tr = css`
display: flex;
flex: 1;
border-bottom: 1px solid ${theme.colors.border_gray};
min-height: 51px;
cursor: default;
align-items: center;

&:hover {
    background-color: ${theme.colors.table_hover_green};
}
`

const sort_btn = css`
display: flex;
align-items: center;
justify-content: center;
height: 30px;
width: 30px;

&:hover img {
opacity: 0.7;
}

img {
width: 18px;
opacity: 1;
}
`
