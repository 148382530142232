/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'
import DefaultCardHeader from './DefaultCardHeader'

class CardHeaderDark extends Component {
    render() {
        const {label} = this.props;
        
        return (
            <DefaultCardHeader extra_class={header}>
              <h2>{ label && label }</h2>
            </DefaultCardHeader>
        )
    }
}
export default CardHeaderDark

const header = css`
background-color: ${theme.colors.dark_gray};
color: ${theme.colors.white};
padding: 0 22px;
width: 100%;

h2 {
    font-size: 22px;
    margin: 0;
    font-weight: 500;
}
`
