import { get } from 'lodash'
import cookie from 'react-cookies'

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'

export function updateSettings(new_settings) {
    return {
        type: UPDATE_SETTINGS,
        new_settings: new_settings
    }
}

export function isConfigured(state) {
    return get(state, ["settings", "configured"], false)
}

export function getSettings(state) {
    return state.settings
}

export function setArmbandScannerNumber(armband_scanner_number) {
    cookie.save('armbandScannerNumber', armband_scanner_number, { path: '/' })
}

export function getArmbandScannerNumber() {
    return cookie.load('armbandScannerNumber')
}
