/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { connect } from 'react-redux'

import CardPrimaryButton from './CardPrimaryButton'
import CardSecondaryButton from './CardSecondaryButton'
import Timestamp from './Timestamp'
import { default_theme as theme } from '../emotion/theme'

class CustomerSignOutDetails extends Component {

    render() {

        const { visit, visit_can_signout, onLeave, display_otp } = this.props
        let { child, guardian, collector } = visit
        if ( ! collector ) {
            collector = guardian
        }
        
        return (
            <form css={main}>
              <div css={title}>
                <p>Visitor information:</p>
              </div>
              <div css={body}>
                <div css={column}>
                  <span css={column_header}>Guest details:</span>
                  <div css={ info_container }>
                    <span>{ child.name }</span>
                  </div>
                  <div css={ info_container }>
                    <span>{ child.surname }</span>
                  </div>
                  <div css={ info_container }>
                    <span>
                      <Timestamp value={child.birth_date} format='date' />
                    </span>
                  </div>
                </div>

                <div css={column}>
                  <span css={column_header}>Guardian details:</span>
                  <div css={ info_container }>
                    <span>{ guardian.name }</span>
                  </div>
                  <div css={ info_container }>
                    <span>{ guardian.surname } </span>
                  </div>
                  <div css={ info_container }>
                    <span>
                      { guardian.email_address }
                    </span>
                  </div>
                </div>

                <div css={[column, column_last]}>
                  <span css={column_header}>Collection details:</span>
                  <div css={ info_container }>
                    <span>{ collector.name }</span>
                  </div>
                  <div css={ info_container }>
                    <span>{ collector.surname }</span>
                  </div>
                  <div css={ info_container }>
                    <span>
                      { collector.contact_number_1 }
                    </span>
                  </div>
                </div>
              </div>

              <div css={footer}>
                <CardPrimaryButton
                    label="Cancel"
                    to="/home"
                    custom_styles={extra_btn_styles}
                />
                { visit_can_signout && display_otp &&
                  <span css={otp}>Security Code: {visit.otp}</span>
                }
                { visit_can_signout &&
                <CardSecondaryButton
                    onClickButton={onLeave}
                    label="Visitor Collected"
                    custom_styles={extra_btn_styles}/>
                }
                { !visit_can_signout &&
                  <div>
                    Inactive
                  </div>
                }
              </div>
            </form>
        )
    }
}

function mapStateToProps(state, props) {

    const { visit, onLeave } = props

    const visit_can_signout = visit && visit.status === 'registered'
    const display_otp = visit && visit.collector && visit.collector.contact_number_1
    
    return {
        visit,
        visit_can_signout,
        onLeave,
        display_otp
    }
}
export default connect(mapStateToProps)(CustomerSignOutDetails)

const main = css`
margin: 0; 
padding: 0;
`

const title = css`
margin-bottom: 40px;

   p {
    margin: 0;
    padding: 0;
       color: ${theme.colors.text_gray_dark};
       font-size: 19px;
   }
`

const column_header = css`
font-size: 14px;
color: ${theme.colors.text_gray};
display: block;
margin-bottom: 10px;
font-weight: 500;
`

const body = css`

display: flex;
`

const column = css`
flex: 1;
margin-right: 50px;
border-right: 1px solid ${theme.colors.border_gray2};
`

const column_last = css`
margin-right: 0;
border: none;
`

const info_container = css`
margin: 24px 0 0 0;
padding: 0 0 12px 16px;
font-weight: 500;
letter-spacing: 1px;
color: ${theme.colors.text_gray_dark2};
font-size: 17px;
`

const footer = css`
display: flex;
justify-content: space-between;
padding-top: 60px;
`

const extra_btn_styles = {
    width: '200px',
    padding: '17px',
    fontSize: '18px',
    height: '55px',
    marginRight: '10px',
    fontWeight: 400,
    textAlign: 'center'
}

const otp = css`
    color: ${theme.colors.text_gray_dark};
    width: 300px;
    padding: 17px;
    font-size: 25px;
    height: 55px;
    margin-right: 10px;
    font-weight: 400;
    text-align: center;
`
