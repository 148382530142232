import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import Login from './Login'
import AuthRouter from './AuthRouter'
import Loading from './Loading'
import MainLayout from './MainLayout'
import { updateSettings, isConfigured } from '../actions/settings'

class MainRouter extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(updateSettings(window.LOCAL_SETTINGS))
    }

    render() {
        const { is_configured } = this.props
        return (
            <div>
              { ! is_configured &&
                <MainLayout>
                  <Loading />
                </MainLayout>
              }

              { is_configured && 
                <Switch>
                  <Route exact={ true } path="/login" component={ Login }/>
                  <AuthRouter />
                </Switch>
              }
            </div>
        )
    }
}

function mapStateToProps(state, props) {

    const is_configured = isConfigured(state)
    return {
        is_configured
    }
}
export default withRouter(connect(mapStateToProps)(MainRouter))
