/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'
import DefaultModal from './DefaultModal'
import CardHeaderDark from './CardHeaderDark'
import CardTextLarge from './CardTextLarge'
import CardPrimaryButton from './CardPrimaryButton'
import CardSecondaryButton from './CardSecondaryButton'

class CustomerDeleteModal extends Component {
    render() {
        const { onClickYes, onCloseModal} = this.props;
        
        return (
            <DefaultModal extra_class={modal} {...this.props}>
              <div css={ main }>
                <div css={ container }>
                  <CardHeaderDark label="Delete visitor" />
                  <div css={body}>
                    <p css={message}>
                      <CardTextLarge extra_class label="Are you sure you want" />
                    </p>
                    <p css={message}>
                      <CardTextLarge extra_class label={"to delete\u00A0"} />
                      <CardTextLarge extra_class={customer_name} label="Sally Andrews" />
                      <CardTextLarge label="?" />
                    </p>
                  </div>
                  <div css={footer}>
                    <CardPrimaryButton onClickButton={onClickYes} label="Yes" />
                    <CardSecondaryButton onClickButton={onCloseModal} label="No" />
                  </div>
                </div>
              </div>
            </DefaultModal>
        )
    }
}
export default CustomerDeleteModal

const modal = css`
  margin: 0 auto;
  width: 100%;
  height: 100%;
background-color: transparent;
`

const main = css`
display: flex;
  flex: 1;
align-items: center;
justify-content: center;
`

const container = css`
display: flex;
flex: 1;
flex-direction: column;
background-color: ${theme.colors.white};
min-width: 600px;
border-bottom-right-radius: 8px;
border-bottom-left-radius: 8px;
`

const body = css`
padding: 40px 40px 0 40px;
`

const message = css`
  margin: 0;
  padding: 0;
  text-align: center;
  span {
 line-height: 43px;
font-size: 30px;
}
`

const customer_name = css`
  color: ${theme.colors.text_green};
`

const footer = css`
padding: 40px 40px 20px 40px;
display: flex;
flex: 1;
justify-content: space-between;
`






















