import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import CustomerRegistration from './CustomerRegistration'
import CustomerRegistrationRevisit from './CustomerRegistrationRevisit'
import CustomerRegistrationVisit from './CustomerRegistrationVisit'
import CustomerRegistrationRevisitConfirm from './CustomerRegistrationRevisitConfirm'

class CustomerRegistrationRouter extends Component {
    
    render() {
        const {match} = this.props;
        return (
            <div>
              <Switch>
                <Route
                    path={`${match.url}/revisit-confirmation`}
                    component={ CustomerRegistrationRevisitConfirm  } />
                <Route
                    path={`${match.url}/revisit/:visitId`}
                    component={ CustomerRegistrationRevisit }/>
                <Route
                    path={`${match.url}/visit/:visitId`}
                    component={ CustomerRegistrationVisit }/>
                <Route
                    path="/"
                    component={ CustomerRegistration }/>
              </Switch>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {}
}
export default withRouter(connect(mapStateToProps)(CustomerRegistrationRouter))
