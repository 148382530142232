export const LIST_KEY__CUSTOMER = 'customers'
export const LIST_KEY__VISIT = 'visits'
export const LIST_KEY__REGISTRATION_VISIT = 'registration_visits'
export const LIST_KEY__DEREGISTRATION_VISIT = 'deregistration_visits'
export const LIST_KEY__TICKET = 'tickets'

export const ENTITY_KEY__CUSTOMER = 'customer'
export const ENTITY_KEY__VISIT = 'visit'
export const ENTITY_KEY__TICKET = 'ticket'

export const GENERIC_ENTITIES = [ ENTITY_KEY__CUSTOMER,
                                  ENTITY_KEY__VISIT,
                                  ENTITY_KEY__TICKET
]

export const HEADER_LIST_NAME__CUSTOM = "customer"
export const HEADER_LIST_NAME__VISIT = "visit"

export const large_col_width = "300px"
export const medium_col_width = "150px"
export const small_col_width = "90px"
export const tiny_col_width = "20px"

export function getCellStyle(s) { 
    // For use with the header lists below

    const flex_grow = s.flex || 0
    const flex_shrink = s.flex || 0
    const flex_basis = s.width
    
    return {
        "maxWidth":s.width,
        "flex": ""+flex_grow+" "+flex_shrink+" "+flex_basis
    }
}


export var CUSTOMER_HEADER_LIST = [ {key:'id', label:"ID", description:"Internal reference", width:medium_col_width} ]

export var VISIT_HEADER_LIST = [ {key:'id', label:"ID", description:"Internal reference", width:medium_col_width} ]

export const VISIT_STATUS_OPTIONS = [
            {
                key: '1',
                label: "Registered",
                name: "registered"
            },
            {
                key: '2',
                label: "Booked",
                name: "booked"
            },
            {
                key: '3',
                label: "Left",
                name: "left"
            }
        ]
