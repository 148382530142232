export const default_theme = {
    colors: {
        white: '#FFFFFF',
        black: '#000000',
        border_gray: '#DEE7EB',
        border_gray2: '#9E9E9E',
        text_gray: "#8e989c",
        text_gray_dark: '#4F5B60',
        text_gray_dark2: '#73858D',
        text_pink: '#FFA5A5',
        text_green: '#9BC890',
        text_disabled: '#D4D9DB',
        table_hover_green: '#DFEEDB',
        table_header_gray: '#F1F5F8',
        table_hover_pink: '#FFA5A5',
        button_green: '#9BC890',
        button_disabled: '#F4F7F8',
        button_gray: "#DEE7EB",
        hover_gray: '#d1dade',
        hover_green: '#8fb985',
        dark_gray: "#72848C",
        app_background: '#F7F8FA',
        gray1: '#666666',
        gray2: '#e6e6e6',
        gray3: '#eeeeee',
        gray4: '#bfbfbf',
        gray5: '#fafafa',
        gray6: '#EEF3F5',
        error: '#FF0000'
    }
}
