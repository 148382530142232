/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../emotion/theme'
import DefaultButton from './DefaultButton'

class CardSecondaryButton extends Component {
    render() {
        const {extra_class} = this.props;
        
        return (
            <DefaultButton
                extra_class={btn}
                parent_extra_class={extra_class}
                {...this.props}/>
        )
    }
}
export default CardSecondaryButton

const btn = css`
background-color: ${theme.colors.button_green};
color: ${theme.colors.white};
border-radius: 5px;
padding: 14px;
min-width: 150px;

&:hover {
   background-color: ${theme.colors.hover_green};
}
} 
`
