import { isObject, startsWith } from 'lodash';
import queryString from 'query-string'
import { authToken } from '../actions/Auth'

function getHeaders(state, customHeaders) {
    let headers = {
        "Content-type": "application/json;charset=UTF-8"
    }
    if (authToken()) {
        headers['X-WONDERDAL-AUTHENTICATION-TOKEN'] = 'Token ' + authToken()
    }
    return Object.assign({}, headers, customHeaders)
}

function addQueryParams(url, params) {
    const parsedUrl = queryString.parseUrl(url)
    parsedUrl.query = Object.assign({}, params, parsedUrl.query)
    return parsedUrl.url + '?' + queryString.stringify(parsedUrl.query)
}

export function absUrl(url) {
    if (startsWith(url, "http://") || startsWith(url, "https://")) {
        return url
    }
    
    const apiBaseUrl = window.LOCAL_SETTINGS.API_BASE
    return apiBaseUrl + url
}

export function post(state, url, data, method='POST') {
    try {
        if (isObject(data)) {
            data = JSON.stringify(data)
        }

        const headers = getHeaders()
        url = addQueryParams(absUrl(url))

        let res = fetch(url, {
            method: method,
            headers: headers,
            body: data
        })

        return res.then(handleResponse)
    } catch (error) {
        throw error
    }
}

export function get(state, url, params, custom_headers) {
    let headers = getHeaders(custom_headers)

    if (params) {
        url = absUrl(url + serialize(params))
    } else {
        url = absUrl(url)
    }

    let res = fetch(url, {
        method: "GET",
        headers: headers
    })

    return res.then(handleResponse)
}

export function put(state, url, data) {
    return post(state, url, data, 'PUT')
}

function handleResponse(response) {
    let contentType = response.headers.get("content-type");
    const isJson = contentType && contentType.indexOf("application/json") !== -1
    const isSuccessful = response.status < 400
    if (isJson) {
        return Promise.all([response.json(), response, isSuccessful])
    } else {
        return Promise.all([response.text(), response, isSuccessful]);
    }
}

function serialize(obj) {
    return '?' + Object.keys(obj).reduce(
        function(a, k){
            if (obj[k] !== null && obj[k] !== undefined) {
                a.push(k + '=' + encodeURIComponent(obj[k]));
            }
            return a
        }, []).join('&')
}
 
