/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../emotion/theme'
import DefaultButton from './DefaultButton'

class MainHeaderPrimaryButton extends Component {
    render() {        
        return (
            <DefaultButton
                extra_class={btn}
                {...this.props} />
        )
    }
}
export default MainHeaderPrimaryButton

const btn = css`
background-color: ${theme.colors.button_green};
color: ${theme.colors.white};
border-radius: 50px;
padding: 0 14px;
height: 50px;
min-width: 100px;
font-weight: 500;
height: 50px;
display: flex;
align-items: center;
justify-content: center;

&:hover, &:active {
background-color: ${theme.colors.hover_green};
}
} 
`
