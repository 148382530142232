/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'
import ReactPortal from './ReactPortal'

class DefaultModal extends Component {
    render() {
        const { onCloseModal, extra_class } = this.props
        return (
            <ReactPortal onClose={ onCloseModal }>
              <div css={ [main] }>
                <div css={ [container, extra_class] }>
                  {this.props.children}
                </div>
              </div>
            </ReactPortal>
        )
    }
}
export default DefaultModal

const main = css`
position: fixed;
z-index: 1;
top: 0;
left: 0;
height: 100%;
width: 100%;
overflow: auto;
background-color: rgba(114,132,140,0.6);
`

const container = css`
position: relative;
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
margin: 5% auto;
width: 500px;
border-bottom-right-radius: 8px;
border-bottom-left-radius: 8px;
background-color: ${theme.colors.white};
`
