/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'
import MainLayout from './MainLayout'
import CardRoundedBottom from './CardRoundedBottom'
import CardHeaderDark from './CardHeaderDark'
import CardTextLarge from './CardTextLarge'
import CardTextMedium from './CardTextMedium'
import CardPrimaryButton from './CardPrimaryButton'
import CardSecondaryButton from './CardSecondaryButton'
import Timestamp from './Timestamp'
import { ensureVisitsLoaded, getVisit } from '../actions/Visit'
import { finaliseTicketRegistration } from '../actions/Ticket'

class CustomerRegistrationRevisit extends Component {

    constructor(props) {
        super(props)
        this.state = { error_msg: null }
    }
    
    componentDidMount() {
        const { dispatch, visit_id, previous_visit_id } = this.props
        dispatch(ensureVisitsLoaded([visit_id]))
        if ( previous_visit_id ) {
            dispatch(ensureVisitsLoaded([previous_visit_id]))
        }
    }

    componentDidUpdate(prev_props) {
        const { dispatch, visit_id, previous_visit_id } = this.props
        dispatch(ensureVisitsLoaded([visit_id]))
        if ( previous_visit_id ) {
            dispatch(ensureVisitsLoaded([previous_visit_id]))
        }
    }

    _onRegister = (evt, registration_args) => {
        const {dispatch, visit, history} = this.props;
        evt.preventDefault()
        const onDone = function(registered_visit) {
            history.push('/registration-complete/' + visit.id)
        }
        const onFail = function(error_result) {
            this.setState({error_msg: error_result})
        }
        dispatch(finaliseTicketRegistration(visit.id, onDone, onFail, registration_args))
    }
    
    onClickNewProfile = (evt) => {
        this._onRegister(evt, {force_new_child: true})
    }

    onFinaliseRegistration = (evt) => {
        this._onRegister(evt, {})
    }

    onContinuePlaying = (evt) => {
        const {history} = this.props;
        evt.preventDefault()
        history.push('/arriving/')
    }
    
    render() {
        const { isLoading, previous_visit } = this.props;
        const { error_msg } = this.state
        const already_playing = (!isLoading && previous_visit.status === 'registered')
        
        return (
            <MainLayout>
              <div css={main}>
                <CardRoundedBottom extra_class={card}>
                <CardHeaderDark label="Register new guest" />
                  { error_msg &&
                    <CardTextMedium label={error_msg} />
                  }
                  {!isLoading && !error_msg && 
                   <div css={body}>
                      { already_playing &&
                        <p>
                          <CardTextLarge extra_class={single_line}
                          label="It looks like you are already playing, since" />
                          <CardTextLarge extra_class={[single_line, green_text]} label={<Timestamp value={previous_visit.registered_date} format="dateshort-time" />} />
                          <CardTextLarge extra_class={single_line} label="Do you want to continue playing" />
                          <CardTextLarge extra_class={single_line} label="or start a new profile as a new player" />
                        </p>
                      }
                      {! already_playing &&
                       <p>
                         <CardTextLarge extra_class={single_line} label="It looks like you have been with us before on" />
                         <CardTextLarge extra_class={[single_line, green_text]} label={<Timestamp value={previous_visit.registered_date} format="dateshort-time" />} />
                         <CardTextLarge extra_class={single_line} label="Do you want to continue playing with that profile" />
                         <CardTextLarge extra_class={single_line} label="or start a new profile" />
                       </p>
                      }

                     <p>
                       <CardTextMedium extra_class={[single_line, pink_text]} label="IMPORTANT" />
                       <CardTextMedium extra_class={single_line} label="If you start a new profile, all previous game data will be" />
                       <CardTextMedium extra_class={single_line} label="replaced with the new data" />
                     </p>

                     <div css={footer}>

                      { already_playing &&
                        <CardPrimaryButton
                            onClickButton={this.onContinuePlaying}
                            label="Continue"
                            custom_styles={extra_btn_styles}
                        />
                      }

                      { ! already_playing && 
                        <CardPrimaryButton
                            to="/arriving/revisit-confirmation"
                            onClickButton={this.onFinaliseRegistration}
                            label="Keep playing"
                            custom_styles={extra_btn_styles}
                        />
                      }
                       <CardSecondaryButton
                           type="button"
                           label="New profile"
                           onClickButton={this.onClickNewProfile}
                           custom_styles={extra_btn_styles}/>
                     </div>
                   </div>
                  }

                  {isLoading && !error_msg && 
                   <div css={loader}>
                     <CardTextLarge label="Loading registration ..." />
                   </div>
                  }
                </CardRoundedBottom>
              </div>
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {

    const visit_id = props.match.params.visitId
    const visit = getVisit(state, visit_id)
    let previous_visit = null
    if ( visit && visit.previous_visit_id ) {
        previous_visit = getVisit(state, visit.previous_visit_id)
    }
    
    return {
        isLoading: previous_visit === null || visit === null,
        visit_id,
        visit,
        previous_visit_id: visit && visit.previous_visit_id,
        previous_visit
    }
}
export default connect(mapStateToProps)(CustomerRegistrationRevisit)

const main = css`
  display: flex; 
 flex: 1;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`

const card = css`
display: flex;
flex: 1;
flex-direction: column;
`

const body = css`
padding: 0 40px 40px 40px;
padding: 80px 140px;

p {
  padding: 0;
  margin: 0 0 40px 0;
}
`

const single_line = css`
display: block;
text-align: center;
line-height: 40px;
`

const pink_text = css`
color: ${theme.colors.text_pink}
`

const green_text = css`
color: ${theme.colors.text_green}
`

const footer = css`
display: flex;
justify-content: space-between;
`

const extra_btn_styles = {
    width: '200px',
    padding: '17px',
    fontSize: '18px',
    height: '55px',
    marginRight: '10px',
    fontWeight: 400,
    textAlign: 'center'
}

const loader = css`
 height: 600px;
 display: flex;
 align-items: center;
 justify-content: center;
 text-align: center;
`
