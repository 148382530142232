/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { Link } from 'react-router-dom'

import { default_theme as theme } from '../emotion/theme'

class DefaultIconLabelButton extends Component {
    renderButton = () => {
        const {
            extra_class,
            label,
            onClickButton,
            disabled,
            type,
            icon_src
        } = this.props;
        
        return(
            <button
                css={ [main, btn, extra_class] }
                disabled={disabled}
                onClick={!disabled ? onClickButton : function() {}} 
                type={type}>
              <span>{label && label}</span>
              
              {icon_src &&
               <img src={icon_src} alt="" />
              }
            </button>
        )
    }
    
    renderLink = () => {
        const {
            extra_class,
            label,
            onClickButton,
            disabled,
            to,
            icon_src
        } = this.props;
        
        return(
            <Link
                to={to}
                css={disabled ? [main, link, extra_class, link_disabled] : [main, link, extra_class]}
                onClick={!disabled ? onClickButton : function() {}} 
            >
              <span>{label && label}</span>
              
              {icon_src &&
               <img src={icon_src} alt="" />
              }
            </Link>
        )
    }
    
    render() {
        const { to } = this.props;
        
        if(to) {
            return this.renderLink()
        } else {
            return this.renderButton()
        }
    }
}
export default DefaultIconLabelButton

const main = css`
background-color: transparent;
color: ${theme.colors.black};
padding: 0;
font-size: 15px;
outline: none;
cursor: pointer;
font-weight: 400;
position: relative;

img {
   display: block;
   position: absolute;
}
`

const btn = css`
border: none;

:disabled {
background-color: ${theme.colors.gray2};
color: ${theme.colors.black};
cursor: default;

&:hover, &:focus, &:active {
background-color: ${theme.colors.gray2};
}
}
`

const link = css`
text-decoration: none;
}
`

const link_disabled = css`
background-color: ${theme.colors.gray2};
color: ${theme.colors.black};
cursor: default;

&:hover, &:focus, &:active {
background-color: ${theme.colors.gray2};
`

