import DatetimePickerField from './DatetimePickerField'

export default class DatePickerField extends DatetimePickerField {

    getDateTimeFormat() {
        return "YYYY-MM-DD"
    }

}

