/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { get, size } from 'lodash'

import MainLayout from './MainLayout'
import CardRoundedBottom from './CardRoundedBottom'
import CardHeaderDark from './CardHeaderDark'
import Loading from './Loading'
import CustomerScanForm from './CustomerScanForm'
import CustomerSignOutDetails from './CustomerSignOutDetails'
import CardTextMedium from './CardTextMedium'
import { LIST_KEY__DEREGISTRATION_VISIT, ENTITY_KEY__VISIT } from '../actions/ItemListKeyRegistry'

import {
    updateListFilter,
    getVisibleItems,
    isLoading,
    getListFilter,
    clear_list_filter_option,
} from '../actions/ItemList'

import { endVisit, fetchVisitsIfNeeded } from '../actions/Visit'

class CustomerSignOut extends Component {

    constructor(props) {
        super(props)
        this.state = {error_msg: null}
    }
    
    componentDidUpdate(prevProps) {
        const { customer_search_form_values } = this.props;

        if(prevProps.customer_search_form_values !== customer_search_form_values) {
            if (customer_search_form_values !== undefined &&
                customer_search_form_values.armband_number &&
                customer_search_form_values.armband_number !== get(prevProps, ["customer_search_form_values", "armband_number"])) {
                this.filterOnArmbandNumber(customer_search_form_values.armband_number)
            }
        }
    }

    componentDidMount() {
        const { dispatch, list_key } = this.props
        dispatch(clear_list_filter_option(list_key, 'armband_number'))
    }

    filterOnArmbandNumber(armband_number) {
        const { dispatch, list_key } = this.props
        dispatch(updateListFilter(list_key, {armband_number: armband_number, status: 'registered'}))
        dispatch(fetchVisitsIfNeeded(list_key))
    }

    onLeave = (evt) => {
        const { dispatch, visit, history } = this.props
        const that = this

        if ( evt ) {
            evt.preventDefault()
        }

        const onLeftDone = function() {
            history.push('/leaving-success/' + visit.id)
        }
        const onLeftFail = function(error_result) {
            that.setState({error_msg: error_result})
        }
        dispatch(endVisit(visit, onLeftDone, onLeftFail))
    }
    
    render() {
        const { visit, found_match, is_loading } = this.props;
        const { error_msg } = this.state
        
        return (
            <MainLayout>
              <div css={main}>
                <CardRoundedBottom extra_class={card}>
                <CardHeaderDark label="Signing out visitor" />
                  { error_msg &&
                    <CardTextMedium label={error_msg} />
                  }
                  { ! error_msg && 
                    <div css={body}>
                      <CustomerScanForm extra_class={form_styles} is_registration_screen={false} />
                      {found_match && 
                       <CustomerSignOutDetails visit={visit} onLeave={this.onLeave} />
                      }
                      { is_loading &&
                         <Loading />
                      } 
                    </div>
                  }
                </CardRoundedBottom>
              </div>
            </MainLayout>
        )
    }
}

function mapStateToProps(state) {

    const list_key = LIST_KEY__DEREGISTRATION_VISIT
    const visible_items = getVisibleItems(state, list_key, ENTITY_KEY__VISIT)
    const is_loading = isLoading(state, list_key)
    const filter = getListFilter(state, list_key)
    const armband_number = (filter && filter.armband_number) || null
    const visit = size(visible_items) > 0 && visible_items[0]
    const found_match = visit.armband_number === armband_number && visit.status === 'registered'
    
    return {
        is_loading: armband_number && is_loading,
        customer_search_form_values: getFormValues('CUSTOMER_SCAN_FORM')(state),
        list_key,
        armband_number,
        visit,
        found_match
    }
}

export default withRouter(connect(mapStateToProps)(CustomerSignOut))

const main = css`
display: flex; 
flex: 1;
max-width: 1000px;
width: 100%;
margin: 0 auto;
`

const card = css`
display: flex;
flex: 1;
flex-direction: column;
`

const body = css`
padding: 60px 40px;
min-height: 650px;
`

const form_styles = css`
padding-bottom: 60px;
margin-bottom: 60px;
`

