/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'

class DropdownSelectItem extends Component {
    onSelectOption = (e) => {
        e.stopPropagation();
        const { on_select, value } = this.props
        on_select(value)
    }

    render() {
        const { label } = this.props

        return (          
            <div onClick={this.onSelectOption} css={dropdown_item}>
              <span>{label}</span>
            </div>
        )
    }
}

export default DropdownSelectItem

const dropdown_item = css`
 font-size: 17px;
 padding: 8px;
border-bottom: 1px solid ${theme.colors.border_gray};
cursor: default;
text-align: left;
color: ${theme.colors.text_gray_dark};

&:hover {
 background-color: ${theme.colors.gray5}
}
`
