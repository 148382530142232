/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'

import MainLayout from './MainLayout'
import CustomerList from './CustomerList'
import CustomerSearch from './CustomerSearch'
import { LIST_KEY__VISIT } from '../actions/ItemListKeyRegistry'

class Customers extends Component {
    render() {        
        return (
            <MainLayout>
              <div css={main}>
                <CustomerSearch list_key={LIST_KEY__VISIT} />
                <CustomerList list_key={LIST_KEY__VISIT} total_label={"Search results"} />
              </div>
            </MainLayout>
        )
    }
}
export default Customers

const main = css`
display: flex; 
flex: 1;
`
