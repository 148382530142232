/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form';

import CardPrimaryIconButton from './CardPrimaryIconButton'
import CardPrimaryButton from './CardPrimaryButton'
import InputField from './InputField'
import DatetimePickerField from './DatetimePickerField'
import DropdownSelectField from './DropdownSelectField'
import search_icon from '../images/search_icon.png'
import { default_theme as theme } from '../emotion/theme'
import 'react-datetime/css/react-datetime.css';
import { VISIT_STATUS_OPTIONS } from '../actions/ItemListKeyRegistry'

class CustomerSearchFilterForm extends Component {

    resetSearch = (evt) => {
        const { dispatch, reset } = this.props
        evt.preventDefault()
        dispatch(reset())
    }
    
    render() {
        const { handleSubmit, status_options } = this.props
        
        return (
            <form css={form_layout} onSubmit={handleSubmit}>
              <div css={ cell }>
                <span css={ cell_label }>Search for visitor <em>(name, surname, email, etc)</em>:</span>
                <Field
                    autoComplete="off"
                    component={ InputField }
                    name="any_field"
                    type="text"/>
              </div>

              <div css={ cell }>
                <span css={ cell_label }>Seach by booked date:</span>
                <div css={cell_two_col}>
                  <div css={ cell_col_first }>
                    <Field
                        component={ DatetimePickerField }
                        name="booked_date_from" 
                        type="text"
                        autoComplete="off"
                        date_format={"DD MMM YYYY"}
                        input_placeholder="From" />
                  </div>
                  <div css={cell_col_last }>
                    <Field
                        component={ DatetimePickerField }
                        name="booked_date_to" 
                        type="text"
                        autoComplete="off"
                        date_format={"DD MMM YYYY"}
                        input_placeholder="To"/>
                  </div>
                </div>
              </div>

              <div css={ cell }>
                <span css={ cell_label }>Search by registered date:</span>
                <div css={cell_two_col}>
                  <div css={ cell_col_first }>
                    <Field
                        component={ DatetimePickerField }
                        name="registered_date_from" 
                        type="text"
                        autoComplete="off"
                        date_format={"DD MMM YYYY"}
                        input_placeholder="From"/>
                  </div>
                  <div css={ cell_col_last }>
                    <Field
                        component={ DatetimePickerField }
                        name="registered_date_to" 
                        type="text"
                        autoComplete="off"
                        date_format={"DD MMM YYYY"}
                        input_placeholder="To"/>
                  </div>
                </div>
              </div>
              
              <div css={ cell }>
                <span css={ cell_label }>Search by sign-out date:</span>
                <div css={cell_two_col}>
                  <div css={ cell_col_first }>
                    <Field
                        component={ DatetimePickerField }
                        name="sign_out_date_from" 
                        type="text"
                        autoComplete="off"
                        date_format={"DD MMM YYYY"}
                        input_placeholder="From"/>
                  </div>

                  <div css={ cell_col_last }>
                    <Field
                        component={ DatetimePickerField }
                        name="sign_out_date_to" 
                        type="text"
                        autoComplete="off"
                        date_format={"DD MMM YYYY"}
                        input_placeholder="To"/>
                  </div>
                </div>
              </div>
              
              <div css={ cell }>
                <span css={ cell_label }>Visitor status:</span>
                <Field
                    autoComplete="off"
                    component={ DropdownSelectField }
                    name="status"
                    type="text"
                    selectable_options={status_options}
                />
              </div>
              <div css={submit_container}>
                <CardPrimaryButton
                    label="Reset"
                    onClickButton={this.resetSearch} />
                <CardPrimaryIconButton
                    type="submit"
                    label="Search"
                    icon_src={search_icon} />
              </div>
            </form>
        )
    }
}
CustomerSearchFilterForm = reduxForm({
    form: 'CUSTOMER_SEARCH_FILTER_FORM'
})(CustomerSearchFilterForm);
function mapStateToProps(state) {
    return {
        status_options: VISIT_STATUS_OPTIONS,
        initialValues: { status: 'registered' }
    }
}
export default connect(mapStateToProps)(CustomerSearchFilterForm)

const form_layout = css`
margin: 0; 
padding: 0;
`

const cell = css`
   margin-bottom: 24px;
`

const cell_two_col = css`
display: flex;
`

const cell_col_first = css`
 flex: 1;
 margin-right: 8px;
`

const cell_col_last = css`
 flex: 1;
 margin-left: 8px;
`

const cell_label = css`
display: inline-block;
 font-size: 14px;
 color: ${theme.colors.text_gray};
 margin-bottom: 5px;
`

const submit_container = css`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`
