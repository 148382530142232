/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { size, keyBy, values, isEmpty } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import DefaultCardHeader from './DefaultCardHeader'
import CustomerTable from './CustomerTable'
import CardPrimaryButton from './CardPrimaryButton'
import CardSecondaryButton from './CardSecondaryButton'
import CardRounded from './CardRounded'
import CustomerDeleteModal from './CustomerDeleteModal'
import CustomerDetailsModal from './CustomerDetailsModal'
import { ENTITY_KEY__VISIT } from '../actions/ItemListKeyRegistry'
import {
    getVisibleItems,
    isLoading,
    getListFilter,
    updateListFilter,
    invalidateList
} from '../actions/ItemList'
import {
    fetchVisitsIfNeeded,
    updateVisit,
    invalidateVisits,
    downloadVisitsCsv
} from '../actions/Visit'

class CustomerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmDeleteModal: false,
            showVisitModal: false,
            selectedVisit: null
        }
    }

    componentDidMount() {
        const { dispatch, filter, list_key } = this.props
        if ( isEmpty(filter) ) {
            dispatch(updateListFilter(list_key, {status: 'registered'}))
        }
        dispatch(fetchVisitsIfNeeded(list_key))
    }
    
    componentDidUpdate(prevProps) {
        const {dispatch, list_key, filter} = this.props;
        if ( filter !== prevProps.filter ) {
            dispatch(fetchVisitsIfNeeded(list_key))
        }
    }

    onSortColumn = (sort_by_type) => {
        // dispatch and and make a call to sort by "sort_by_type"
    }

    openConfirmDeleteModal = (visit_id) => {
        // Set selected customer
        const { visits_by_id } = this.props
        const visit = visits_by_id[visit_id]
        this.setState({showConfirmDeleteModal: true,
                       selectedVisit: visit})
    }

    closeConfirmDeleteModal = () => {
        // remove selected customer
        this.setState({showConfirmDeleteModal: false})
    }

    onConfirmDeleteCustomer = () => {
        // get selected customer
        // dispatch deleteCustomer
        this.closeConfirmDeleteModal()
    }

    openVisitModal = (visit_id) => {
        // Set selected customer
        const { visits_by_id } = this.props
        const visit = visits_by_id[visit_id]
        this.setState({showVisitModal: true, selectedVisit: visit})
    }

    closeVisitModal = (evt) => {
        // remove selected customer
        if ( evt ) {
            evt.preventDefault()
        }
        this.setState({showVisitModal: false})
    }

    onSubmitVisit = (visit_id, values) => {
        const { dispatch, list_key } = this.props
        const that = this
        const on_done = function() {
            dispatch(invalidateVisits([visit_id]))
            dispatch(invalidateList(list_key))
            that.closeVisitModal()
        }
        
        dispatch(updateVisit(visit_id, values, on_done))
    }

    onRefreshList = (evt) => {
        const { dispatch, list_key } = this.props
        evt.preventDefault()
        dispatch(invalidateList(list_key))
        dispatch(fetchVisitsIfNeeded(list_key))
    }

    exportToExcel = (evt) => {
        const { dispatch, list_key } = this.props
        evt.preventDefault()
        dispatch(downloadVisitsCsv(list_key))
    }

    render() {
        const { visits, is_loading, total_label } = this.props;
        const { showConfirmDeleteModal, showVisitModal, selectedVisit } = this.state;
        const num_visits = size(visits)
        
        return (
            <CardRounded extra_class={main}>
              <DefaultCardHeader extra_class={header_row}>
                <span css={header_title}>{ total_label || <span>Total vistors playing</span> }: </span>
                <span css={header_count}>
                  { is_loading && <span>...</span> }
                  { ! is_loading && num_visits }
                </span>
              </DefaultCardHeader>

              <CustomerTable
                  onClickVisit={this.openVisitModal}
                  onSortColumn={ this.onSortColumn }
                  onClickDelete={ this.openConfirmDeleteModal }
                  visits={ visits } />
              <div css={btn_row}>
                <CardPrimaryButton label="Export to Excel"
                                   onClickButton={this.exportToExcel}/>
                <CardSecondaryButton onClickButton={this.onRefreshList}
                                     label={"Refresh"} />
              </div>

              {showConfirmDeleteModal &&
               <CustomerDeleteModal
                   visit={selectedVisit}
                   onCloseModal={this.closeConfirmDeleteModal}
                   onClickYes={this.onConfirmDeleteCustomer}
               />
              }

              {showVisitModal &&
               <CustomerDetailsModal
                   visit={selectedVisit}
                   onCloseModal={this.closeVisitModal}
                   onClickSave={this.onSubmitVisit}
               />
              }
            </CardRounded>
        )
    }
}

function mapStateToProps(state, props) {
    const { list_key, total_label } = props
    const visible_items = getVisibleItems(state, list_key, ENTITY_KEY__VISIT)
    const visits_by_id = keyBy(values(visible_items), "id")
    const is_loading = isLoading(state, list_key)
    const filter = getListFilter(state, list_key)
    
    return {
        visits: visible_items,
        visits_by_id,
        list_key,
        is_loading,
        filter,
        total_label
    }
}

export default connect(mapStateToProps)(CustomerList)

const main = css`
flex: 1;
display: flex;
flex-direction: column;
height: 644px;
`

const header_row = css`
padding: 0 10px;
border-bottom: 0;
color: ${theme.colors.dark_gray};
`

const header_title = css`
font-size: 16px;
`

const header_count = css`
font-size: 35px;
display: inline-block;
padding-left: 16px;
`

const btn_row = css`
display: flex;
justify-content: space-between;
align-items: center;
border-top: 1px solid ${theme.colors.border_gray};
padding: 40px;
`
