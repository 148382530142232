/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'

import DefaultModal from './DefaultModal'
import CustomerDetailsForm from './CustomerDetailsForm'

class CustomerDetailsModal extends Component {
    render() {
        const { onCloseModal, onClickSave, visit } = this.props;
        
        return (
            <DefaultModal extra_class={modal} {...this.props}>              
              <CustomerDetailsForm
                  visit={visit}
                  onSubmit={(values) => onClickSave(visit.id, values)}
                  onCancel={onCloseModal}
              />
            </DefaultModal>
        )
    }
}

export default CustomerDetailsModal

const modal = css`
  width: 53vw;
`
