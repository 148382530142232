/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { connect } from 'react-redux'

import MainLayout from './MainLayout'
import GameServerTestChildRegistrationForm from './GameServerTestChildRegistrationForm'
import GameServerTestChildDeregistrationForm from './GameServerTestChildDeregistrationForm'
import GameServerTestChildInformationForm from './GameServerTestChildInformationForm'
import GameServerTestMostRecentArmbandScanForm from './GameServerTestMostRecentArmbandScanForm'

class GameServerTestPage extends Component {
    
    render() {
        return (
            <MainLayout>
              <div css={main}>
                <div css={spacer}>
                  <GameServerTestChildRegistrationForm />
                </div>
                <div css={spacer}>
                  <GameServerTestChildDeregistrationForm />
                </div>
                <div css={spacer}>
                  <GameServerTestChildInformationForm />
                </div>
                <div css={spacer}>
                  <GameServerTestMostRecentArmbandScanForm />
                </div>
              </div>
            </MainLayout>
        )
    }
}

function mapStateToProps(state) {
    return {}
}
export default connect(mapStateToProps)(GameServerTestPage)

const main = css`
display: flex; 
flex: 1;
`

const spacer = css`
margin: 50px;
width: 25%;
`
