/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'

class CardSecondaryButton extends Component {
    render() {
        return (
            <div css={layout}>{this.props.children}</div>
        )
    }
}
export default CardSecondaryButton

const layout = css`
font-size: 9px;
} 
`
