/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form';

import CardPrimaryIconButton from './CardPrimaryIconButton'
import InputField from './InputField'
import { default_theme as theme } from '../emotion/theme'

import {
    deregisterChildFromGameServer,
} from '../actions/GameServer'

class GameServerTestChildDeregistrationForm extends Component {
    constructor() {
        super()
        this.state = { result: false }
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        const { dispatch, form_values } = this.props
        if (form_values) {
            dispatch(deregisterChildFromGameServer(form_values.deregister_child_id))
        }
    }

    
    render() {
        const { response } = this.props
        
        return (
            <form css={form_layout} onSubmit={this.handleSubmit}>
              <span css={ title }>Deregister a child</span>
              <div>
                <span css={ cell_label }><em>Child ID</em>:</span>
                <Field
                    autoComplete="off"
                    component={ InputField }
                    name="deregister_child_id"
                    type="text"/>
              </div>
              <div css={submit_container}>
                <CardPrimaryIconButton              
                    custom_styles={btn}
                    type="submit"
                    label="Deregister Child" />
              </div>
              {this.props.childDeregistered &&
               <div css={submit_container}>
                 <span css={[cell_label, success_label]}><em>Successfully registered child</em></span>
                 <span css={[response, success_label]}><em><div dangerouslySetInnerHTML={{ __html: response }} /></em></span>w
               </div>
              }
            </form>
        )
    }
}
GameServerTestChildDeregistrationForm = reduxForm({
    form: 'CHILD_DEREGISTRATION_FORM'
})(GameServerTestChildDeregistrationForm);
function mapStateToProps(state) {
    return {
        form_values: getFormValues('CHILD_DEREGISTRATION_FORM')(state),
        childDeregistered: state.game_server.childDeregistered,
        response: state.game_server.deregistrationResponse
    }
}
export default connect(mapStateToProps)(GameServerTestChildDeregistrationForm)

const form_layout = css`
margin: 0; 
padding: 0;
`

const title = css`
display: inline-block;
 font-size: 20px;
 color: ${theme.colors.text_gray};
 margin-bottom: 20px;
`


const cell_label = css`
display: inline-block;
 font-size: 14px;
 color: ${theme.colors.text_gray};
 margin-bottom: 5px;
`

const submit_container = css`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
`

const success_label = css`
  color: ${theme.colors.text_green}
`
const btn = css`
background-color: ${theme.colors.button_green};
color: ${theme.colors.white};
border-radius: 50px;
padding: 0 14px;
min-width: 200px;
font-weight: 500;
height: 50px;
display: flex;
align-items: center;
justify-content: center;
font-size: 17px;

&:hover, &:active {
background-color: ${theme.colors.hover_green};
}
`

