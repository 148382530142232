/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'

import icon_arriving from '../images/icon_arriving.png'
import icon_leaving from '../images/icon_leaving.png'
import icon_customers from '../images/icon_customerList.png'
import MainLayout from './MainLayout'
import HomeCardButton from './HomeCardButton'
import CustomerList from './CustomerList'

import { updateListFilter } from '../actions/ItemList'
import { LIST_KEY__REGISTRATION_VISIT } from '../actions/ItemListKeyRegistry'

class Home extends Component {

    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(updateListFilter(LIST_KEY__REGISTRATION_VISIT, {status: 'registered'}))
    }
    
    onClickArriving = () => {}

    onClickLeaving = () => {}

    onClickCustomerList = () => {
        const { history } = this.props;
        history.push('/customers')
    }
    
    render() {
        return (
            <MainLayout>
              <div css={main}>
                <div css={btns_column}>
                  <HomeCardButton label="Arriving" icon_src={icon_arriving} to="/arriving" />
                  <HomeCardButton label="Leaving" icon_src={icon_leaving} to="/leaving" />
                  <HomeCardButton label="Customer list" icon_src={icon_customers} to="/customers" />
                </div>
                <CustomerList list_key={LIST_KEY__REGISTRATION_VISIT} />
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state) {
    return {}
}
export default connect(mapStateToProps)(Home)

const main = css`
  display: flex; 
 flex: 1;
`

const btns_column = css`
display: flex;
flex-direction: column;
height: 644px;
width: 450px;
margin-right: 100px;
justify-content: space-between;
`
