/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form'
import { get } from 'lodash'
import InputField from './InputField'
import DatePickerField from './DatePickerField'
import DropdownSelectField from './DropdownSelectField'
import CardPrimaryButton from './CardPrimaryButton'
import CardSecondaryButton from './CardSecondaryButton'
import DefaultCardHeader from './DefaultCardHeader'
import CardSecondaryInfoSection from './CardSecondaryInfoSection'
import { default_theme as theme } from '../emotion/theme'
import 'react-datetime/css/react-datetime.css'
import { flattenVisit, resendCertificateEmail } from '../actions/Visit'
import { flattenTicket } from '../actions/Ticket'
import { ensureTicketsLoaded, getTicket } from '../actions/Ticket'
import { VISIT_STATUS_OPTIONS } from '../actions/ItemListKeyRegistry'
import moment from 'moment'

class CustomerDetailsForm extends Component {

    constructor(props) {
        super(props)
        this.state = { email_resent_at: null }
    }
    
    componentDidMount() {
        const { dispatch, visit } = this.props
        if ( visit && visit.ticket ) {
            dispatch(ensureTicketsLoaded([visit.ticket]))
        }
    }

    componentDidUpdate(new_props) {
        const { dispatch, visit } = new_props
        const { customer_details_form_values, initialValues } = this.props
        if ( visit && visit.ticket ) {
            dispatch(ensureTicketsLoaded([visit.ticket]))
        }

        if (customer_details_form_values !== new_props.customer_details_form_values) {
            if (customer_details_form_values !== undefined &&
                customer_details_form_values.status &&
                customer_details_form_values.status !==
                    get(new_props, ["customer_details_form_values", "status"]) &&
                customer_details_form_values.status !== initialValues.status) {
                window.alert("Warning: this could desynchronize the game server")
            }
        }
    }

    resendVisitEmail = (evt) => {
        const { dispatch, visit } = this.props
        const that = this
        evt.preventDefault()

        const on_ok = function() {
            that.setState({email_resent_at: moment()})
        }
        
        dispatch(resendCertificateEmail(visit.id, on_ok))
    }

    render() {
        const { status_options, onCancel, handleSubmit, visit } = this.props
        const { email_resent_at } = this.state
        
        return (
            <form css={form_layout} onSubmit={handleSubmit}>
              <DefaultCardHeader extra_class={header}>
                <h2>Visitor details</h2>
              </DefaultCardHeader>
              
              <div css={ [container, gray_container] }>
                <span css={container_header}>Child details:</span>
                <div css={row}>
                  <div css={ cell }>
                    <span css={ cell_label }>Name:</span>
                    <Field
                        autoComplete="off" 
                        component={ InputField }
                        name="child_name"
                        extra_class={input_styles}
                        type="text"/>
                  </div>

                  <div css={ cell }>
                    <span css={ cell_label }>Surname:</span>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="child_surname"
                        extra_class={input_styles}
                        type="text"/>
                  </div>
                  
                  <div css={ [cell, last_cell] }>
                    <span css={ cell_label }>Date of birth:</span>
                    <Field
                        component={ DatePickerField }
                        name="child_date_of_birth" 
                        type="text"
                        date_format={"DD / MM / YYYY"}
                        extra_class={input_styles}
                        autoComplete="off"/>
                  </div>
                </div>

                <div css={row}>
                  <div css={ cell }>
                    <span css={ cell_label }>Ticket details:</span>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="ticket_nr"
                        extra_class={input_styles}
                        type="text"/>
                  </div>
                  
                  <div css={ cell }>
                    <span css={ cell_label }>Armband details:</span>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="armband_number"
                        extra_class={input_styles}
                        type="text"/>
                  </div>
                  
                  <div css={ [cell, last_cell] }>
                    <span css={ cell_label }>Visitor status:</span>
                    <Field
                        autoComplete="off"
                        component={ DropdownSelectField }
                        name="status"
                        type="text"
                        form_input_styles={input_styles}
                        selectable_options={status_options}
                    />
                  </div>
                </div>
              </div>

              <div css={ container }>
                <span css={container_header}>Guardian details:</span>
                <div css={row}>
                  <div css={ cell }>
                    <span css={ cell_label }>Name:</span>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="guardian_name"
                        extra_class={input_styles}
                        type="text"/>
                  </div>

                  <div css={ cell }>
                    <span css={ cell_label }>Surname:</span>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="guardian_surname"
                        extra_class={input_styles}
                        type="text"/>
                  </div>
                  
                  <div css={ [cell, last_cell] }>
                    <span css={ cell_label }>E-mail address:</span>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="guardian_email_address"
                        extra_class={input_styles}
                        type="text"/>
                  </div>
                </div>

                <div css={row}>
                  <div css={ cell }>
                    <span css={ cell_label }>Contact number 1:</span>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="guardian_contact_number_1"
                        extra_class={input_styles}
                        type="text"/>
                  </div>
                  
                  <div css={ cell }>
                    <span css={ cell_label }>Contact number 2:</span>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="guardian_contact_number_2"
                        extra_class={input_styles}
                        type="text"/>
                  </div>
                  
                  <div css={ [cell, last_cell] }></div>
                </div>
              </div>

              <div css={ container }>
                <span css={container_header}>Collection details:</span>
                <div css={row}>
                  <div css={ cell }>
                    <span css={ cell_label }>Name:</span>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="collector_name"
                        extra_class={input_styles}
                        type="text"/>
                  </div>

                  <div css={ cell }>
                    <span css={ cell_label }>Surname:</span>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="collector_surname"
                        extra_class={input_styles}
                        type="text"/>
                  </div>
                  
                  <div css={ [cell, last_cell] }>
                    <span css={ cell_label }>Contact number 1:</span>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="collector_contact_number_1"
                        extra_class={input_styles}
                        type="text"/>
                  </div>
                </div>

                <div css={row}>                    
                  <div css={ cell }>
                    <span css={ cell_label }>Contact number 2:</span>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="collector_contact_number_2"
                        extra_class={input_styles}
                        type="text"/>
                  </div>

                  <div css={ cell }></div>
                  <div css={ [cell, last_cell] }></div>
                </div>
              </div>

              <div css={footer}>
                <CardPrimaryButton onClickButton={onCancel} label="Cancel" />
                <CardSecondaryButton type="submit" label="Save" />
              </div>
              <div css={footer}>
                <CardSecondaryInfoSection>
                  <CardSecondaryButton onClickButton={this.resendVisitEmail} label="Resend certificate" />
                  { email_resent_at && <span>Email sent</span> }
                  &nbsp;
                  <br/>
                  visit ref {visit.ref}.
                  <br/>
                  child ref {visit.child.ref}
                  <br/>
                </CardSecondaryInfoSection>
              </div>
            </form>
        )
    }
}
CustomerDetailsForm = reduxForm({
    form: 'CUSTOMER_DETAILS_FORM'
})(CustomerDetailsForm);

function mapStateToProps(state, props) {
    const { visit } = props
    
    const ticket = visit && getTicket(state, visit.ticket)
    const initial_values = Object.assign({}, flattenVisit(visit), flattenTicket(ticket))

    return {
        visit,
        ticket,
        customer_details_form_values: getFormValues('CUSTOMER_DETAILS_FORM')(state),
        initialValues: initial_values,
        enableReinitialize: true,
        status_options: VISIT_STATUS_OPTIONS
    }
}
export default connect(mapStateToProps)(CustomerDetailsForm)

const form_layout = css`
margin: 0; 
padding: 0;
width: 100%;
padding: 0;
`

const header = css`
background-color: ${theme.colors.dark_gray};
padding: 0 22px;
width: 100%;
display: flex;
justify-content: space-between;

h2 {
    font-size: 22px;
    margin: 0;
    font-weight: 500;
color: ${theme.colors.white};
}
`

const container = css`
 padding: 20px 40px 20px 40px;
`

const container_header = css`
font-weight: 400;
 display: block;
 font-size: 19px;
color: ${theme.colors.text_gray};
margin-bottom: 20px;

`

const gray_container = css`
background-color: ${theme.colors.gray6};
padding: 40px 40px 20px 40px;
`

const row = css`
 display: flex;
 margin-bottom: 24px;
`

const cell = css`
   margin: 0 40px 0 0;
   flex: 1;
`

const last_cell = css`
margin: 0;
`

const cell_label = css`
display: inline-block;
 font-size: 14px;
 color: ${theme.colors.text_gray};
 margin-bottom: 5px;
`

const input_styles = css`
font-weight: 500;
color: ${theme.colors.text_gray_dark2};
font-size: 18px;
`

const footer = css`
padding: 0 40px 40px 40px;
display: flex;
flex: 1;
justify-content: space-between;
width: 100%;
`
