import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { isLoggedIn } from '../actions/Auth'
import Home from './Home'
import Login from './Login'
import Customers from './Customers'
import CustomerRegistrationRouter from './CustomerRegistrationRouter'
import CustomerSignOut from './CustomerSignOut'
import CustomerRegistrationComplete from './CustomerRegistrationComplete'
import CustomerSignOutComplete from './CustomerSignOutComplete'
import GameServerTestPage from './GameServerTestPage'
import ArmbandConfiguration from './ArmbandConfiguration'
import { getArmbandScannerNumber } from '../actions/settings'

class AuthRouter extends Component {

    componentDidMount() {
        this.refresh()
    }

    componentDidUpdate(prev_props) {
        this.refresh()
    }

    refresh() {
        const { location, history, is_logged_in } = this.props
        const armbandScannerNumberIsConfigured = getArmbandScannerNumber()
        if ( is_logged_in && ! armbandScannerNumberIsConfigured &&
             location.pathname.indexOf('armbandConfiguration') === -1 ) {
            history.push('armbandConfiguration')
        }
    }
    
    render() {

        const { is_logged_in } = this.props
        if ( ! is_logged_in ) {
            return (
                <div>
                  <Switch>
                    <Route path="/" component={ Login }/>
                  </Switch>
                </div>
            )
        }
        
        return (
            <div>
              <Switch>
                <Route path="/home" component={ Home }/>
                <Route path="/customers" component={ Customers }/>
                <Route path="/leaving" component={ CustomerSignOut }/>
                <Route path="/registration-complete/:visitId" component={ CustomerRegistrationComplete  } />
                <Route path="/arriving" component={ CustomerRegistrationRouter  } />
                <Route path="/leaving-success/:visitId" component={ CustomerSignOutComplete  } />
                <Route path="/test-game-server" component={ GameServerTestPage  } />
                <Route path="/armbandConfiguration" component={ ArmbandConfiguration } />
                <Route path="/" component={ Home }/>
              </Switch>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        is_logged_in: isLoggedIn(state)
    }
}
export default withRouter(connect(mapStateToProps)(AuthRouter))
