/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../emotion/theme'

import DefaultIconLabelButton from './DefaultIconLabelButton'

class HomeCardButton extends Component {
    render() {        
        return (
            <DefaultIconLabelButton extra_class={btn} {...this.props} />
        )
    }
}

export default HomeCardButton

const btn = css`
  height: 185px;
  display: flex;
  align-items: center;
  justify-content: space-between;
box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.23);
background-color: ${theme.colors.white};
border-radius: 8px;
padding: 0 50px;
color: ${theme.colors.dark_gray};
font-size: 32px;

img {
position: unset;
height: 110px;
}
`
