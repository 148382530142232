/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'

class DefaultCardHeader extends Component {
    render() {
        const {extra_class} = this.props;
        
        return (
            <div css={[header, extra_class]}>
              {this.props.children}
            </div>
        )
    }
}
export default DefaultCardHeader

const header = css`
height: 69px;
align-items: center;
display: flex;
`
