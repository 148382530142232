import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import auth from './auth'
import game_server from './game_server'
import settings from './settings'
import loading from './loading'
import item from './item'
import item_list from './item_list'
import maintenance from './maintenance'

const appReducer = combineReducers({
    form: formReducer,
    auth,
    item,
    item_list,
    game_server,
    maintenance,
    settings,
    loading
})

const rootReducer = (state, action) => {
    return appReducer(state, action)
}
export default rootReducer;
