import { SubmissionError } from 'redux-form'
import { has, get, isEmpty, size } from 'lodash'
import * as http from '../lib/http'
import cookie from 'react-cookies'
 
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN"
export const CLEAR_AUTH_TOKEN = "CLEAR_AUTH_TOKEN"
export const USER_LOGGED_IN = "USER_LOGGED_IN"
export const ANNOUNCE_LOGGING_IN_USER = "ANNOUNCE_LOGGING_IN_USER"
export const ANNOUNCE_USER_NOT_LOGGED_IN = "ANNOUNCE_USER_NOT_LOGGED_IN"

export function clearAuthentication() {
    cookie.remove('authToken', { path: '/' })
    return {
        type: CLEAR_AUTH_TOKEN
    }
}

export function isLoggedIn() {
    const token = authToken()
    return ! isEmpty(token) && size(token) > 0
}

export function isLoading(state) {
    return get(state, ["auth", "isLoading"], false)
}

export function authToken() {
    return cookie.load('authToken')
}

export function announceUserNotLoggedIn() {
    return {
        type: ANNOUNCE_USER_NOT_LOGGED_IN
    }
}

export function announceUserLoggedIn(token, user) {
    cookie.save('authToken', token, { path: '/' })
    
    return {
        type: USER_LOGGED_IN,
        token,
        user
    }
}

export function announceLoggingInUser() {
    return {
        type: ANNOUNCE_LOGGING_IN_USER
    }
}

export function login(email, password) {
    return async (dispatch, getState) => {
        try {
            dispatch(announceLoggingInUser())
            let [json, success] = await http.post(getState(), 'auth/login/', { email: email, password: password})

            if (success.status === 200 && has(json, 'token')) {
                dispatch(announceUserLoggedIn(json.token, json.user))
            } else {
                dispatch(announceUserNotLoggedIn())
                throw new SubmissionError({_error: "Failed to login with your credentials"})
            }

            if (!json.success) {
                throw new SubmissionError({_error: json.message})
            }
        } catch (e) {
            if (e instanceof SubmissionError) {
                throw e
            } else {
                throw new SubmissionError({_error: 'Login failed ' + e.message})
            }
        }
    }
}
