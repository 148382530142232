/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'

import MainLayout from './MainLayout'
import CardRoundedBottom from './CardRoundedBottom'
import CardHeaderDark from './CardHeaderDark'
import CardTextMedium from './CardTextMedium'
import { ensureVisitsLoaded, getVisit } from '../actions/Visit'
import { finaliseTicketRegistration } from '../actions/Ticket'

class CustomerRegistrationVisit extends Component {

    constructor(props) {
        super(props)
        this.state = {error_msg: null,
                      registering: false}
    }
    
    componentDidMount() {
        this.tryRegistration()
    }

    componentDidUpdate(prev_props) {
        this.tryRegistration()
    }

    tryRegistration = () => {
        const { dispatch, visit_id, visit } = this.props
        const { registering } = this.state
        dispatch(ensureVisitsLoaded([visit_id]))

        if ( visit && ! registering ) {
            dispatch(finaliseTicketRegistration(visit_id, this.onRegistrationFinalised, this.onRegistrationFailed))
            this.setState({registering: true})
        }
    }

    onRegistrationFailed = (error_result) => {
        this.setState({error_msg: "Failed to activate armband: " + error_result})
    }

    onRegistrationFinalised = () => {
        const { history, visit_id } = this.props
        history.push('/registration-complete/' + visit_id)
    }
    
    render() {
        const { error_msg } = this.state
        return (
            <MainLayout>
              <div css={main}>
                <CardRoundedBottom extra_class={card}>
                  <CardHeaderDark label="Registration" />
                  { error_msg && 
                    <CardTextMedium label={error_msg} />
                  }
                  { ! error_msg &&
                    <CardTextMedium label="Activating armband" />
                  }
                </CardRoundedBottom>
              </div>
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {

    const visit_id = props.match.params.visitId
    const visit = getVisit(state, visit_id)
    
    return {
        visit_id,
        visit
    }
}
export default connect(mapStateToProps)(CustomerRegistrationVisit)

const main = css`
  display: flex; 
 flex: 1;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`

const card = css`
display: flex;
flex: 1;
flex-direction: column;
`
