import { has, keyBy, get } from 'lodash'
import moment from 'moment'
import { VISIT_STATUS_OPTIONS } from './ItemListKeyRegistry'

export function fetchCustomers() {
    return [
        {
            id: '3',
            customer_name: 'Sally',
            customer_surname: 'Jones',
            customer_date_of_birth: "2007-02-12T13:02:28+0000",
            guardian_name: "Debbie",
            guardian_surname: "Andrews",
            guardian_email: "email@email.com",
            guardian_contact_number_1: "072 222 222",
            guardian_contact_number_2: "072 333 333",
            collectee_name: "Debbie",
            collectee_surname: "Andrews",
            collectee_contact_number_1: "NA",
            collectee_contact_number_2: "NA",
            status: "Playing",
            exit_time: "2019-02-01T14:12:22+0000",
            flag_exit_time: false
        },
        {
            id: '3',
            customer_name: 'Ben',
            customer_surname: 'Rossouw',
            customer_date_of_birth: "2007-02-12T13:02:28+0000",
            guardian_name: "Mr Eddie",
            guardian_surname: "Doe",
            guardian_email: "email@email.com",
            guardian_contact_number_1: "072 222 222",
            guardian_contact_number_2: "072 333 333",
            collectee_name: "Mr Eddie",
            collectee_surname: "Doe",
            collectee_contact_number_1: "NA",
            collectee_contact_number_2: "NA",
            status: "Playing",
            exit_time: "2019-01-31T09:11:00+0000",
            flag_exit_time: true
        },
        {
            id: '3',
            customer_name: 'Sally',
            customer_surname: 'Jones',
            customer_date_of_birth: "2007-02-12T13:02:28+0000",
            guardian_name: "Debbie",
            guardian_surname: "Andrews",
            guardian_email: "email@email.com",
            guardian_contact_number_1: "072 222 222",
            guardian_contact_number_2: "072 333 333",
            collectee_name: "Debbie",
            collectee_surname: "Andrews",
            collectee_contact_number_1: "NA",
            collectee_contact_number_2: "NA",
            status: "Playing",
            exit_time: "2019-02-01T14:12:22+0000",
            flag_exit_time: false
        },
        {
            id: '3',
            customer_name: 'Sally',
            customer_surname: 'Jones',
            customer_date_of_birth: "2007-02-12T13:02:28+0000",
            guardian_name: "Debbie",
            guardian_surname: "Andrews",
            guardian_email: "email@email.com",
            guardian_contact_number_1: "072 222 222",
            guardian_contact_number_2: "072 333 333",
            collectee_name: "Debbie",
            collectee_surname: "Andrews",
            collectee_contact_number_1: "NA",
            collectee_contact_number_2: "NA",
            status: "Playing",
            exit_time: "2019-02-01T14:12:22+0000",
            flag_exit_time: false
        },
        {
            id: '3',
            customer_name: 'Sally',
            customer_surname: 'Jones',
            customer_date_of_birth: "2007-02-12T13:02:28+0000",
            guardian_name: "Debbie",
            guardian_surname: "Andrews",
            guardian_email: "email@email.com",
            guardian_contact_number_1: "072 222 222",
            guardian_contact_number_2: "072 333 333",
            collectee_name: "Debbie",
            collectee_surname: "Andrews",
            collectee_contact_number_1: "NA",
            collectee_contact_number_2: "NA",
            status: "Playing",
            exit_time: "2019-02-01T14:12:22+0000",
            flag_exit_time: false
        },
        {
            id: '3',
            customer_name: 'Sally',
            customer_surname: 'Jones',
            customer_date_of_birth: "2007-02-12T13:02:28+0000",
            guardian_name: "Debbie",
            guardian_surname: "Andrews",
            guardian_email: "email@email.com",
            guardian_contact_number_1: "072 222 222",
            guardian_contact_number_2: "072 333 333",
            collectee_name: "Debbie",
            collectee_surname: "Andrews",
            collectee_contact_number_1: "NA",
            collectee_contact_number_2: "NA",
            status: "Playing",
            exit_time: "2019-02-01T14:12:22+0000",
            flag_exit_time: false
        },
        {
            id: '3',
            customer_name: 'Sally',
            customer_surname: 'Jones',
            customer_date_of_birth: "2007-02-12T13:02:28+0000",
            guardian_name: "Debbie",
            guardian_surname: "Andrews",
            guardian_email: "email@email.com",
            guardian_contact_number_1: "072 222 222",
            guardian_contact_number_2: "072 333 333",
            collectee_name: "Debbie",
            collectee_surname: "Andrews",
            collectee_contact_number_1: "NA",
            collectee_contact_number_2: "NA",
            status: "Playing",
            exit_time: "2019-02-01T14:12:22+0000",
            flag_exit_time: false
        },
        {
            id: '3',
            customer_name: 'Sally',
            customer_surname: 'Jones',
            customer_date_of_birth: "2007-02-12T13:02:28+0000",
            guardian_name: "Debbie",
            guardian_surname: "Andrews",
            guardian_email: "email@email.com",
            guardian_contact_number_1: "072 222 222",
            guardian_contact_number_2: "072 333 333",
            collectee_name: "Debbie",
            collectee_surname: "Andrews",
            collectee_contact_number_1: "NA",
            collectee_contact_number_2: "NA",
            status: "Playing",
            exit_time: "2019-02-01T14:12:22+0000",
            flag_exit_time: false
        },
        {
            id: '3',
            customer_name: 'Sally',
            customer_surname: 'Jones',
            customer_date_of_birth: "2007-02-12T13:02:28+0000",
            guardian_name: "Debbie",
            guardian_surname: "Andrews",
            guardian_email: "email@email.com",
            guardian_contact_number_1: "072 222 222",
            guardian_contact_number_2: "072 333 333",
            collectee_name: "Debbie",
            collectee_surname: "Andrews",
            collectee_contact_number_1: "NA",
            collectee_contact_number_2: "NA",
            status: "Playing",
            exit_time: "2019-02-01T14:12:22+0000",
            flag_exit_time: false
        },
        {
            id: '3',
            customer_name: 'Sally',
            customer_surname: 'Jones',
            customer_date_of_birth: "2007-02-12T13:02:28+0000",
            guardian_name: "Debbie",
            guardian_surname: "Andrews",
            guardian_email: "email@email.com",
            guardian_contact_number_1: "072 222 222",
            guardian_contact_number_2: "072 333 333",
            collectee_name: "Debbie",
            collectee_surname: "Andrews",
            collectee_contact_number_1: "NA",
            collectee_contact_number_2: "NA",
            status: "Playing",
            exit_time: "2019-02-01T14:12:22+0000",
            flag_exit_time: false
        },
        {
            id: '3',
            customer_name: 'Sally',
            customer_surname: 'Jones',
            customer_date_of_birth: "2007-02-12T13:02:28+0000",
            guardian_name: "Debbie",
            guardian_surname: "Andrews",
            guardian_email: "email@email.com",
            guardian_contact_number_1: "072 222 222",
            guardian_contact_number_2: "072 333 333",
            collectee_name: "Debbie",
            collectee_surname: "Andrews",
            collectee_contact_number_1: "NA",
            collectee_contact_number_2: "NA",
            status: "Playing",
            exit_time: "2019-02-01T14:12:22+0000",
            flag_exit_time: false
        },
        {
            id: '3',
            customer_name: 'Sally',
            customer_surname: 'Jones',
            customer_date_of_birth: "2007-02-12T13:02:28+0000",
            guardian_name: "Debbie",
            guardian_surname: "Andrews",
            guardian_email: "email@email.com",
            guardian_contact_number_1: "072 222 222",
            guardian_contact_number_2: "072 333 333",
            collectee_name: "Debbie",
            collectee_surname: "Andrews",
            collectee_contact_number_1: "NA",
            collectee_contact_number_2: "NA",
            status: "Playing",
            exit_time: "2019-02-01T14:12:22+0000",
            flag_exit_time: false
        }
    ]
}

// Helpers

export function getCustomerStatusDisplayName(value) {
    return get(keyBy(VISIT_STATUS_OPTIONS, "name")[value], ["label"], "")
}

export function getCustomerFilterObject(values) {
    let updated_filters = values
    
    if (values !== undefined) {        
        if (has(values, 'booked_from_date')) {
            updated_filters = Object.assign(updated_filters, {booked_from_date: moment(values.booked_from_date).toISOString()})
        }

        if (has(values, 'booked_to_date')) {
            updated_filters = Object.assign(updated_filters, {booked_to_date: moment(values.booked_to_date).toISOString()})
        }
        
        if (has(values, 'registered_from_date')) {
            updated_filters = Object.assign(updated_filters, {registered_from_date: moment(values.registered_from_date).toISOString()})
        }

        if (has(values, 'registered_to_date')) {
            updated_filters = Object.assign(updated_filters, {registered_to_date: moment(values.registered_to_date).toISOString()})
        }
        
        if (has(values, 'signed_out_from_date')) {
            updated_filters = Object.assign(updated_filters, {signed_out_from_date: moment(values.signed_out_from_date).toISOString()})
        }

        if (has(values, 'signed_out_to_date')) {
            updated_filters = Object.assign(updated_filters, {signed_out_to_date: moment(values.signed_out_to_date).toISOString()})
        }
        
        if (has(values, 'customer')) {
            updated_filters = Object.assign(updated_filters, {customer: values.customer})
        }
        
        if (has(values, 'status')) {
            updated_filters = Object.assign(updated_filters, {status: values.status})
        }
    }

    return updated_filters;
}
