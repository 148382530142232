/** @jsx jsx */
import React, { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { connect } from 'react-redux'
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import {isEmpty} from 'lodash';
import { withRouter } from 'react-router'

import CardPrimaryButton from './CardPrimaryButton'
import CardPrimaryIconButton from './CardPrimaryIconButton'
import ScanArmbandInputField from './ScanArmbandInputField'
import ScanTicketInputField from './ScanTicketInputField'
import { default_theme as theme } from '../emotion/theme'
import 'react-datetime/css/react-datetime.css'
import { triggerArmbandScanForThisTerminal } from '../actions/GameServer'
import search_icon from '../images/search_icon.png'
import { LIST_KEY__TICKET } from '../actions/ItemListKeyRegistry'
import { updateListFilter } from '../actions/ItemList'
import { fetchTicketsIfNeeded } from '../actions/Ticket'

class CustomerScanForm extends Component {
    constructor(props) {
        super(props);
        this.ticketInput = React.createRef();
        this.armbandInput = React.createRef();
        this.state = { armband_error_msg: null }
    }

    scanTicketInput = () => {
        this.ticketInput.current.focus()
        
    }

    scanArmbandInput = () => {
        const { dispatch } = this.props
        const that = this
        dispatch(change('CUSTOMER_SCAN_FORM', 'armband_number', ''))
        
        this.armbandInput.current.focus()

        const on_ok = function(payload) {
            const armband_code = payload.armband_code
            dispatch(that.props.change('armband_number', armband_code))
        }

        const on_fail = function(error_values) {
            that.setState({armband_error_msg:error_values.error_msg || error_values})
        }

        that.setState({armband_error_msg:null})
        dispatch(triggerArmbandScanForThisTerminal(on_ok, on_fail))
    }

    forceFilterOnTicket = (evt, ticket_nr) => {
        const { dispatch, list_key, customer_search_form_values } = this.props
        evt.preventDefault()
        this.setState({armband_number:customer_search_form_values.armband_number})
        dispatch(updateListFilter(LIST_KEY__TICKET, {ticket_nr: ticket_nr}))
        dispatch(fetchTicketsIfNeeded(list_key))
    }
    
    render() {
        const {
            ticket_details,
            is_registration_screen,
            is_signout_screen,
            extra_class,
            customer_search_form_values
        } = this.props
        const { armband_error_msg } = this.state
        
        return (
            <div css={[form_layout, extra_class]}>
              {is_registration_screen &&
               <div css={ cell }>
                  <span css={ cell_label }>Please scan the ticket</span>
                  <CardPrimaryButton
                      label="Scan now"
                      custom_styles={extra_btn_styles}
                      onClickButton={this.scanTicketInput}
                  />
                  <Field
                      autoComplete="off"
                      component={ ScanTicketInputField }
                      name="ticket"
                      type="text"
                      input_ref={this.ticketInput}
                      extra_class={input_styles}
                  />
                  { customer_search_form_values &&
                    customer_search_form_values.ticket &&
                  <CardPrimaryIconButton
                      extra_class={icon_btn}
                      icon_src={search_icon}
                      onClickButton={(evt) => this.forceFilterOnTicket(evt, customer_search_form_values.ticket)}
                  />
                  }
                  <div css={ticket_details_class}>
                  {!isEmpty(ticket_details) && 
                   <span>12:30pm  Saturday 3 September 2019</span>
                  }
                  </div> 
            </div>
                  }

              <div css={ cell }>
                <span css={ cell_label }>
                  {is_registration_screen && "Please scan the armband to start registration" }
                  {is_signout_screen && "Please scan the armband to sign visitor out" }
                </span>
                <CardPrimaryButton
                    label="Scan now"
                    custom_styles={extra_btn_styles}
                    onClickButton={this.scanArmbandInput}
                />
                <Field
                    autoComplete="off"
                    component={ ScanArmbandInputField }
                    name="armband_number"
                    type="text"
                    input_ref={this.armbandInput}
                    extra_class={input_styles} />
                { armband_error_msg &&
                  <div>
                    Failed to scan armband: {armband_error_msg}
                  </div>
                }
              </div>
            </div>
        )
    }
}
CustomerScanForm = reduxForm({
    form: 'CUSTOMER_SCAN_FORM'
})(CustomerScanForm);
function mapStateToProps(state, props) {
    const { is_registration_screen } = props
    const list_key = LIST_KEY__TICKET
    return {
        is_registration_screen,
        is_signout_screen: !is_registration_screen,
        list_key,
        customer_search_form_values: getFormValues('CUSTOMER_SCAN_FORM')(state),
    }
}
export default withRouter(connect(mapStateToProps)(CustomerScanForm))

const form_layout = css`
margin: 0; 
padding: 0;
border-bottom: 1px solid ${theme.colors.border_gray};
padding-bottom: 40px;
margin-bottom: 40px;
`

const cell = css`
display: flex; 
align-items: center;
padding-top: 40px;
`

const cell_label = css`
display: inline-block;
font-size: 18px;
color: ${theme.colors.text_gray};
margin-bottom: 5px;
font-weight: 400;
width: 219px;
`

const input_styles = css`
height: 55px;
font-weight: 500;
font-size: 19px;
padding: 0 20px;
color: ${theme.colors.dark_gray};
flex: 1;
`

const extra_btn_styles = {
width: '240px',
padding: '17px',
fontSize: '18px',
fontWeight: 500,
height: '55px',
marginRight: '10px'
}

const ticket_details_class = css`
height: 40px;
display: flex;
align-items: center;

span {
color: ${theme.colors.text_green};
font-size: 18px;
}
`

const icon_btn = css`
padding: 17px;
margin-left: 10px;
align-items: center;
height: 55px;
background-color: ${theme.colors.button_gray};
color: ${theme.colors.dark_gray};
border-radius: 5px;
display: inline-block;
width: 55px;
&:hover {
   background-color: ${theme.colors.hover_gray};
}

img {
bottom: 17px;
}
`
