/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'
import MainLayout from './MainLayout'
import CardHeaderDark from './CardHeaderDark'
import CardTextLarge from './CardTextLarge'
import CardRoundedBottom from './CardRoundedBottom'
import CardSecondaryButton from './CardSecondaryButton'
import Timestamp from './Timestamp'
import Loading from './Loading'
import { ensureVisitsLoaded, getVisit } from '../actions/Visit'

class CustomerRegistrationComplete extends Component {

    componentDidMount() {
        const { dispatch, visit_id } = this.props
        dispatch(ensureVisitsLoaded([visit_id]))
    }
    
    render() {
        const { is_loading, visit } = this.props
        
        return (
            <MainLayout>
              <div css={main}>
                { is_loading && <Loading />}
                { ! is_loading && 
                  <CardRoundedBottom extra_class={card}>
                    <CardHeaderDark label={<span>Registration complete for {visit.child.name}</span>} />
                    <div css={body}>
                      <div css={text_body}>
                        <p>
                          <CardTextLarge label="Done! Please proceed into Wonderdal." /> 
                        </p>
                        <p>
                          <CardTextLarge label={"Visit ends:\u00A0"} />
                          <CardTextLarge extra_class={green_text} label={<Timestamp value={visit.visit_ends_at} format='short-time' />} />
                        </p>
                      </div>

                      <div css={footer}> 
                        <CardSecondaryButton
                            to="/arriving"
                            label="Next registration"
                            custom_styles={extra_btn_styles}/>
                      </div>
                    </div>
                  </CardRoundedBottom>
                }
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const visit_id = props.match.params.visitId
    const visit = getVisit(state, visit_id)
    return {
        visit_id,
        visit,
        is_loading: !visit || !visit.id
    }
}
export default connect(mapStateToProps)(CustomerRegistrationComplete)

const main = css`
  display: flex; 
 flex: 1;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`

const card = css`
display: flex;
flex: 1;
flex-direction: column;
`

const body = css`
padding: 40px;

p {
  padding: 0;
  margin: 0 0 40px 0;
}
`

const text_body = css`
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
line-height: 40px;
  }
`

const green_text = css`
    color: ${theme.colors.text_green};
`

const footer = css`
display: flex;
justify-content: flex-end;
`

const extra_btn_styles = {
    width: '220px',
    padding: '17px',
    fontSize: '18px',
    height: '55px',
    marginRight: '10px',
    fontWeight: 400,
    textAlign: 'center'
}
