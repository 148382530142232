/** @jsx jsx */
import { Component } from 'react';
import { Link } from 'react-router-dom'
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'

class DefaultButton extends Component {
    renderButton = () => {
        const {
            extra_class,
            label,
            onClickButton,
            disabled,
            type,
            custom_styles
        } = this.props;
        
        return (
            <button
                css={ [main, btn, extra_class] }
                style={custom_styles}
                disabled={disabled}
                onClick={!disabled ? onClickButton : function() {}} 
                type={type}>
              { label && label }
            </button>
        )
    }

    renderLink = () => {
        const {
            extra_class,
            label,
            onClickButton,
            disabled,
            to,
            custom_styles
        } = this.props;
        
        return(
            <Link
                to={to}
                css={disabled ?
                     [main, link, extra_class, link_disabled] :
                     [main, link, extra_class]
                }
                style={custom_styles}
                onClick={!disabled ? onClickButton : function() {}} 
            >
              {label && label}
            </Link>
        )
    }
    
    render() {
        const { to } = this.props;
        
        if(to) {
            return this.renderLink()
        } else {
            return this.renderButton()
        }
    }
}
export default DefaultButton

const main = css`
background-color: transparent;
color: ${theme.colors.black};
padding: 0;
font-size: 15px;
outline: none;
cursor: pointer;
font-weight: 400;
`

const btn = css`
border: none;

:disabled {
background-color: ${theme.colors.button_disabled};
color: ${theme.colors.text_disabled};
    cursor: default;

&:hover, &:focus, &:active {
    background-color: ${theme.colors.button_disabled};
}
}
`

const link = css`
  text-decoration: none;
}
`

const link_disabled = css`
background-color: ${theme.colors.button_disabled};
color: ${theme.colors.text_disabled};
cursor: default;

&:hover, &:focus, &:active {
background-color: ${theme.colors.button_disabled};
`
