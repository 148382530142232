/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'

class CardTextLarge extends Component {
    render() {
        const {label, extra_class} = this.props;
        
        return (
            <span css={[main, extra_class]}>{label}</span>
        )
    }
}
export default CardTextLarge

const main = css`
  display: inline-block;
  font-size: 33px;
    line-height: 33px;
color: ${theme.colors.text_gray_dark};
}
`
