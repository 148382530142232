import { SubmissionError } from 'redux-form'
import { has } from 'lodash'
import * as http from '../lib/http'
import { getArmbandScannerNumber } from '../actions/settings'
import { loadingOn, loadingOff } from '../actions/Loading'

export const CHILD_REGISTERED = "CHILD_REGISTERED"
export const ANNOUNCE_REGISTERING_CHILD = "ANNOUNCE_REGISTERING_CHILD"
export const ANNOUNCE_CHILD_NOT_REGISTERED = "ANNOUNCE_CHILD_NOT_REGISTERED"

export const CHILD_DEREGISTERED = "CHILD_DEREGISTERED"
export const ANNOUNCE_DEREGISTERING_CHILD = "ANNOUNCE_DEREGISTERING_CHILD"
export const ANNOUNCE_CHILD_NOT_DEREGISTERED = "ANNOUNCE_CHILD_NOT_DEREGISTERED"

export const CHILD_INFORMATION = "CHILD_INFORMATION"
export const ANNOUNCE_RETRIEVING_CHILD_INFORMATION = "ANNOUNCE_RETRIEVING_CHILD_INFORMATION"
export const ANNOUNCE_CHILD_INFORMATION_NOT_RETRIEVED = "ANNOUNCE_CHILD_INFORMATION_NOT_RETRIEVED"

export const MOST_RECENT_ARMBAND_SCAN = "MOST_RECENT_ARMBAND_SCAN"
export const ANNOUNCE_RETRIEVING_MOST_RECENT_ARMBAND_SCAN = "ANNOUNCE_RETRIEVING_MOST_RECENT_ARMBAND_SCAN"
export const ANNOUNCE_MOST_RECENT_ARMBAND_SCAN_NOT_RETRIEVED = "ANNOUNCE_MOST_RECENT_ARMBAND_SCAN_NOT_RETRIEVED"


export function announceChildNotRegistered() {
    return {
        type: ANNOUNCE_CHILD_NOT_REGISTERED
    }
}

export function announceChildRegistered(response) {
    return {
        type: CHILD_REGISTERED,
        response
    }
}

export function announceRegisteringChild() {
    return {
        type: ANNOUNCE_REGISTERING_CHILD
    }
}


export function announceChildNotDeregistered() {
    return {
        type: ANNOUNCE_CHILD_NOT_DEREGISTERED
    }
}

export function announceChildDeregistered(deregistrationResponse) {
    return {
        type: CHILD_DEREGISTERED,
        deregistrationResponse
    }
}

export function announceDeregisteringChild() {
    return {
        type: ANNOUNCE_DEREGISTERING_CHILD
    }
}

export function announceChildInformationNotRetrieved() {
    return {
        type: ANNOUNCE_CHILD_INFORMATION_NOT_RETRIEVED
    }
}

export function announceChildInformation(child_information, childInformationResponse) {
    return {
        type: CHILD_INFORMATION,
        child_information,
        childInformationResponse
    }
}

export function announceRetrievingChildInformation() {
    return {
        type: ANNOUNCE_RETRIEVING_CHILD_INFORMATION
    }
}

export function announceMostRecentArmbandScanNotRetrieved() {
    return {
        type: ANNOUNCE_MOST_RECENT_ARMBAND_SCAN_NOT_RETRIEVED
    }
}

export function announceMostRecentArmbandScan(recentArmbandScan, recentArmbandScanResponse) {
    return {
        type: MOST_RECENT_ARMBAND_SCAN,
        recentArmbandScan,
        recentArmbandScanResponse
    }
}

export function announceRetrievingMostRecentArmbandScan() {
    return {
        type: ANNOUNCE_RETRIEVING_MOST_RECENT_ARMBAND_SCAN
    }
}

export function registerChildOnGameServerFromVisit(visit, on_ok, on_fail) {
    return registerChildOnGameServer({register_child_id: visit.child.ref,
                                      register_armband_number: visit.armband_number,
                                      register_child_firstname: visit.child.name,
                                      register_child_lastname: visit.child.surname,
                                      register_child_date_of_birth: visit.child.birth_date},
                                     on_ok, on_fail)
}

export function registerChildOnGameServer(values, on_ok, on_fail) {
    return async (dispatch, getState) => {
        const params = {
            child_id: values.register_child_id,
            child_armband_id: values.register_armband_number,
            child_firstname: values.register_child_firstname,
            child_lastname: values.register_child_lastname,
            child_date_of_birth: values.register_child_date_of_birth
        }

        try {
            dispatch(announceRegisteringChild())
            const url = `${window.LOCAL_SETTINGS.GAME_SERVER_API_BASE}child/register/`
            dispatch(loadingOn())
            let [json] = await http.post(getState(), url, params)
            json = returnJSON(json)
            dispatch(loadingOff())
            if (json.status === 'success') {
                dispatch(announceChildRegistered())
                if ( on_ok ) {
                    on_ok()
                }
            } else {
                dispatch(announceChildNotRegistered())
                if ( on_fail ) {
                    on_fail(json)
                }
            }
        } catch (e) {
            if (e instanceof SubmissionError) {
                throw e
            } else {
                throw new SubmissionError({_error: 'Child Registration Failed ' + e.message})
            }
        }
    }
}

export function deregisterChildFromGameServer(child_id, on_ok, on_fail) {
    return async (dispatch, getState) => {
        try {
            dispatch(announceDeregisteringChild())
            const url = `${window.LOCAL_SETTINGS.GAME_SERVER_API_BASE}child/${child_id}/deregister/`
            dispatch(loadingOn())
            let [json] = await http.put(getState(), url)
            json = returnJSON(json)
            dispatch(loadingOff())
            if (json.status === 'success') {
                dispatch(announceChildDeregistered())
                if ( on_ok ) {
                    on_ok()
                }
            } else {
                dispatch(announceChildNotDeregistered())
                if ( on_fail ) {
                    on_fail(json)
                }
            }
        } catch (e) {
            if (e instanceof SubmissionError) {
                throw e
            } else {
                throw new SubmissionError({_error: 'Child Deregistration Failed ' + e.message})
            }
        }
    }
}

export function getChildInformationFromGameServer(child_id, on_ok, on_fail) {
    return async (dispatch, getState) => {
        try {
            dispatch(announceRetrievingChildInformation())
            const url = `${window.LOCAL_SETTINGS.GAME_SERVER_API_BASE}child/${child_id}/`
            dispatch(loadingOn())
            let [json] = await http.get(getState(), url)
            json = returnJSON(json)
            dispatch(loadingOff())
            if (json.status === 'success' && has(json, 'payload')) {
                dispatch(announceChildInformation(json.payload, json))
                if ( on_ok ) {
                    on_ok(json.payload)
                }
            } else {
                dispatch(announceChildInformationNotRetrieved())
                if ( on_fail ) {
                    on_fail(json)
                }
            }

        } catch (e) {
            dispatch(announceChildInformationNotRetrieved())
            on_fail({error_msg:e.message})
        }
    }
}

export function triggerArmbandScanForThisTerminal(on_ok, on_fail) {
    const terminal_id = getArmbandScannerNumber()
    return getMostRecentArmbandScan(terminal_id, on_ok, on_fail)
}

export function getMostRecentArmbandScan(terminal_id, on_ok, on_fail) {
    return async (dispatch, getState) => {
        try {
            dispatch(announceRetrievingMostRecentArmbandScan())
            const url = `${window.LOCAL_SETTINGS.GAME_SERVER_API_BASE}armband/${terminal_id}/`
            dispatch(loadingOn())
            let [json] = await http.get(getState(), url)
            json = returnJSON(json)
            dispatch(loadingOff())
            if (json.status === 'success' && has(json, 'payload')) {
                dispatch(announceMostRecentArmbandScan(json.payload, json))
                if ( on_ok ) {
                    on_ok(json.payload)
                }
            } else {
                dispatch(announceMostRecentArmbandScanNotRetrieved())
                if ( on_fail ) {
                    on_fail(json)
                }
            }
        } catch (e) {
            dispatch(announceMostRecentArmbandScanNotRetrieved())
            on_fail({error_msg:e.message})
        }
    }
}

export function returnJSON(json) {
    try {
        return JSON.parse(json)
    } catch (e) {
        return json
    }
}
