/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'
import MainLayout from './MainLayout'
import CardHeaderDark from './CardHeaderDark'
import CardTextLarge from './CardTextLarge'
import CardRoundedBottom from './CardRoundedBottom'
import CardSecondaryButton from './CardSecondaryButton'
import Loading from './Loading'
import { getVisit, ensureVisitsLoaded } from '../actions/Visit'

class CustomerSignOutComplete extends Component {

    componentDidMount() {
        const { dispatch, visit_id } = this.props
        dispatch(ensureVisitsLoaded([visit_id]))
    }
    
    render() {
        const { visit, is_loading } = this.props
        return (
            <MainLayout>
              <div css={main}>
                <CardRoundedBottom extra_class={card}>
                    { is_loading && 
                      <CardHeaderDark label={<Loading/>} />
                    }
                    { !is_loading && 
                      <div>
                        <CardHeaderDark label="Sign-out successful" />
                        <div css={body}>
                          <div css={text_body}>
                            <p>
                              <CardTextLarge extra_class={green_text} label={visit.child.name} />
                              <CardTextLarge label={<span>&nbsp;has been successfully signed out</span>} />
                            </p>
                          </div>

                          <div css={footer}> 
                            <CardSecondaryButton
                                to="/leaving"
                                label="Next sign-out"
                                custom_styles={extra_btn_styles}/>
                          </div>
                        </div>
                      </div>
                    }
                </CardRoundedBottom>
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const visit_id = props.match.params.visitId
    const visit = getVisit(state, [visit_id])
    return {
        visit_id,
        visit,
        is_loading: !visit || !visit.id
    }
}
export default connect(mapStateToProps)(CustomerSignOutComplete)

const main = css`
  display: flex; 
 flex: 1;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`

const card = css`
display: flex;
flex: 1;
flex-direction: column;
`

const body = css`
padding: 40px;

p {
  padding: 0;
  margin: 0 0 40px 0;
}
`

const text_body = css`
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
line-height: 40px;
  }
`

const green_text = css`
    color: ${theme.colors.text_green};
`

const footer = css`
display: flex;
justify-content: flex-end;
`

const extra_btn_styles = {
    width: '220px',
    padding: '17px',
    fontSize: '18px',
    height: '55px',
    marginRight: '10px',
    fontWeight: 400,
    textAlign: 'center'
}
