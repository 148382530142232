/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form';
import { isEmpty } from 'lodash'

import CardPrimaryIconButton from './CardPrimaryIconButton'
import InputField from './InputField'
import DatePickerField from './DatePickerField'
import { default_theme as theme } from '../emotion/theme'
import 'react-datetime/css/react-datetime.css';

import {
    registerChildOnGameServer,
} from '../actions/GameServer'

class GameServerTestChildRegistrationForm extends Component {
    constructor() {
        super()
        this.state = { result: false }
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        const { dispatch, form_values } = this.props
        if (!isEmpty(form_values)) {
            dispatch(registerChildOnGameServer(form_values))
        }
    }

    render() {
        const { response } = this.props
        
        return (
            <form css={form_layout} onSubmit={this.handleSubmit}>
              <span css={ title }>Register a child</span>
              <div>
                <span css={ cell_label }><em>Child ID</em>:</span>
                <Field
                    autoComplete="off"
                    component={ InputField }
                    name="register_child_id"
                    type="text"/>
                <span css={ cell_label }><em>Armband ID</em>:</span>
                <Field
                    autoComplete="off"
                    component={ InputField }
                    name="register_child_armband_id"
                    type="text"/>
                <span css={ cell_label }><em>Child First Name</em>:</span>
                <Field
                    autoComplete="off"
                    component={ InputField }
                    name="register_child_firstname"
                    type="text"/>
                <span css={ cell_label }><em>Child Last Name</em>:</span>
                <Field
                    autoComplete="off"
                    component={ InputField }
                    name="register_child_lastname"
                    type="text"/>
                <span css={ cell_label }><em>Child Date of Birth</em>:</span>
                <Field
                    component={ DatePickerField }
                    name="register_child_date_of_birth" 
                    type="text"
                    autoComplete="off"
                    date_format={"DD MMM YYYY"}/>
              </div>
              <div css={submit_container}>
                <CardPrimaryIconButton              
                    custom_styles={btn}
                    type="submit"
                    label="Register Child" />
              </div>
              {this.props.childRegistered &&
               <div css={submit_container}>
                 <span css={[cell_label, success_label]}><em>Successfully registered child</em></span>
                 <span css={[response, success_label]}><em><div dangerouslySetInnerHTML={{ __html: response }} /></em></span>
               </div>
              }
            </form>
        )
    }
}
GameServerTestChildRegistrationForm = reduxForm({
    form: 'CHILD_REGISTRATION_FORM'
})(GameServerTestChildRegistrationForm);
function mapStateToProps(state) {
    return {
        form_values: getFormValues('CHILD_REGISTRATION_FORM')(state),
        childRegistered: state.game_server.childRegistered,
        response: state.game_server.response
    }
}
export default connect(mapStateToProps)(GameServerTestChildRegistrationForm)

const form_layout = css`
margin: 0; 
padding: 0;
`

const title = css`
display: inline-block;
 font-size: 20px;
 color: ${theme.colors.text_gray};
 margin-bottom: 20px;
`


const cell_label = css`
display: inline-block;
 font-size: 14px;
 color: ${theme.colors.text_gray};
 margin-bottom: 5px;
`

const submit_container = css`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
`

const success_label = css`
  color: ${theme.colors.text_green}
`
const btn = css`
background-color: ${theme.colors.button_green};
color: ${theme.colors.white};
border-radius: 50px;
padding: 0 14px;
min-width: 200px;
font-weight: 500;
height: 50px;
display: flex;
align-items: center;
justify-content: center;
font-size: 17px;

&:hover, &:active {
background-color: ${theme.colors.hover_green};
}
`

