/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../emotion/theme'

class ScanTicketInputField extends Component {
    render() {
        const {
            extra_class,
            placeholder,
            input,
            label,
            type,
            tabIndex,
            input_ref,
            ...extraProps
        } = this.props
        
        return (
            <input {...input}
                   {...extraProps}
                   placeholder={placeholder}
                   css={[form_input, extra_class]}
                   type={type}
                   ref={input_ref} />
        )
    }
}
export default ScanTicketInputField;

const form_input = css`
border: 1px solid ${theme.colors.border_gray};
border-radius: 5px;
height: 40px;
display: flex;
align-items: center;
font-size: 17px;
padding: 0 8px 0 8px;
outline: none;
width: 100%;
color: ${theme.colors.text_gray_dark};
font-weight: 500;

&:focus, &:active {
  border: 1px solid ${theme.colors.gray4};
}
`
