/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'
import MainHeader from './MainHeader'
import { isLoading, loadingOff } from '../actions/Loading'

class MainLayout extends Component {
    forceLoadingOff = (evt) => {
        const { dispatch } = this.props
        evt.preventDefault()
        dispatch(loadingOff())
    }
    
    render() {
        const { children, is_loading } = this.props
        return (
            <div css={ main }>
              { is_loading &&
                <div onClick={this.forceLoadingOff}>
                  <div css={ loading_container }></div>
                  <div css={ loading_text }>Loading...</div>
                </div>
              }
              <MainHeader />
              <div css={ body_content }>
                <div css={ body_container }>
                  { children }
                </div>
              </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const is_loading = isLoading(state)
    
    return {
        is_loading
    }
}
export default connect(mapStateToProps)(MainLayout)

const main = css`
background-color: ${theme.colors.app_background};
min-height: 100vh;
`
const loading_container = css`
width: 100vw;
background-color: lightgrey;
opacity: 0.7;
height: 99%;
position: absolute;
z-index: 999;
text-align: center;
`
const loading_text = css`
position: absolute;
top: 47%;
left: 47%;
font-size: 30px;
z-index 1000;
`

const body_content = css`
max-width: 1600px;
width: 100%;
margin: 0 auto;
`

const body_container = css`
padding: 70px 16px 16px 16px;
`
