/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import moment from 'moment'
import { default_theme as theme } from '../emotion/theme'
import trash_icon from '../images/trash_icon.png'
import DefaultIconButton from './DefaultIconButton'
import Timestamp from './Timestamp'

class VisitTableRow extends Component {
    onClickDelete = () => {
        const { visit, onClickDelete } = this.props
        onClickDelete(visit.id)
    }

    onClickVisit = (evt) => {
        const { visit, onClickVisit } = this.props
        evt.preventDefault()
        onClickVisit(visit.id)
    }
    
    render() {
        const { visit } = this.props
        const { child, guardian } = visit

        if ( visit.loaded === false ) {
            return (
                <div css={ tr }>
                </div>
            )
        }

        const visit_expired = visit.visit_ends_at && moment().isAfter(visit.visit_ends_at)
        
        return (
            <div css={ tr } onClick={this.onClickVisit}>
              <div css={ td }>
                {child.name}
              </div>
              <div css={ td }>
                {child.surname}
              </div>
              <div css={ td }>
                {guardian &&
                 guardian.name}
              </div>
              <div css={ visit_expired ? [td, flagged_exit_time] : [td] }>
                <Timestamp value={visit.visit_ends_at} format="dateshort-time" />
              </div>
              <div css={ [td, icon_cell] }>
                { false && <DefaultIconButton
                    extra_class={icon_btn}
                    type="button"
                    onClickButton={this.onClickDelete}
                    icon_src={trash_icon}
                           />
                }
              </div>
            </div>
        )
    }
}
export default VisitTableRow

const tr = css`
display: flex;
flex: 1;
border-bottom: 1px solid ${theme.colors.border_gray};
min-height: 51px;
cursor: default;
align-items: center;

&:hover {
    background-color: ${theme.colors.table_hover_green};
}
`

const td = css`
display: flex;
flex: 1;
font-weight: 300;
border-right: 1px solid ${theme.colors.border_gray};
height: 100%;
min-height: 51px;
max-height: 100px;
align-items: center;
padding: 10px;
color: ${theme.colors.dark_gray};
font-size: 16px;
font-weight: 400;
cursor: pointer;
`

const icon_cell = css`
min-height: 51px;
max-height: 100px;
width: 50px;
display: flex;
flex: none;
align-items: center;
justify-content: center;
padding: 0;
border-right: none;
`

const icon_btn = css`
display: flex;
align-items: center;
justify-content: center;
height: 30px;
width: 30px;

&:hover img {
opacity: 0.7;
}

img {
width: 18px;
opacity: 1;
}
`

const flagged_exit_time = css`
background-color: ${theme.colors.table_hover_pink};
color: ${theme.colors.white};
`
