/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'

import DefaultCard from './DefaultCard'

class CardRoundedBottom extends Component {
    render() {
        const {extra_class} = this.props;
        
        return (
            <DefaultCard
                extra_class={card}
                component_child_class={extra_class}>
              {this.props.children}
            </DefaultCard>
        )
    }
}
export default CardRoundedBottom

const card = css`
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
`
