/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../emotion/theme'
import DefaultButton from './DefaultButton'

class MainHeaderSecondaryButton extends Component {
    render() {        
        return (
            <DefaultButton
                extra_class={btn}
                {...this.props}/> 
        )
    }
}
export default MainHeaderSecondaryButton

const btn = css`
background-color: ${theme.colors.white};
color: ${theme.colors.text_gray_dark};
border 1px solid ${theme.colors.text_gray_dark};
border-radius: 50px;
padding: 0 14px;
min-width: 100px;
font-weight: 500;
height: 50px;
display: flex;
align-items: center;
justify-content: center;

&:hover, &:active {
background-color: ${theme.colors.text_gray_dark};
color: ${theme.colors.white};
}
} 
`
