/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form';
import { jsx, css } from '@emotion/core'
import { size } from 'lodash'
import { withRouter } from 'react-router'
import MainLayout from './MainLayout'
import CardRoundedBottom from './CardRoundedBottom'
import CardHeaderDark from './CardHeaderDark'
import CustomerRegistrationForm from './CustomerRegistrationForm'
import CustomerScanForm from './CustomerScanForm'
// import CustomerRegistrationRevisit from './CustomerRegistrationRevisit'
// import CustomerRegistrationRevisitConfirm from './CustomerRegistrationRevisitConfirm'
import CardTextMedium from './CardTextMedium'
import { LIST_KEY__TICKET, ENTITY_KEY__TICKET } from '../actions/ItemListKeyRegistry'
import {
    startTicketRegistration
} from '../actions/Ticket'
import {
    getVisibleItems,
    isLoading,
    getListFilter,
    clear_list_filter_option
} from '../actions/ItemList'

class CustomerRegistration extends Component {

    constructor(props) {
        super(props)
        this.state = {registration_status: "form"}
    }

    componentDidMount() {
        const { dispatch, list_key } = this.props
        dispatch(clear_list_filter_option(list_key, 'ticket_nr'))
    }

    onRegistrationFinalised = (values) => {
        const { history } = this.props
        history.push('/registration-complete/' + values.visit_id)
    }

    onRegistrationStarted = (values) => {
        const { history } = this.props
        if ( values.previous_visit_id ) {
            history.push('/arriving/revisit/'+values.visit_id)
        } else {
            history.push('/arriving/visit/'+values.visit_id)
        }
    }
    
    handleSubmit = (values) => {
        const {dispatch, ticket_nr, customer_search_form_values} = this.props;
        values.ticket_nr = ticket_nr
        this.setState({registration_status: "registering"})
        dispatch(startTicketRegistration(ticket_nr, customer_search_form_values.armband_number, values, this.onRegistrationStarted))
    }

    onClickCancel = () => {
        const {history} = this.props;
        history.push('/home')
    }

    render() {
        const { is_loading, ticket_nr, ticket, found_match, customer_search_form_values } = this.props
        const initial_form_values = (found_match && ticket) || {}
        const { registration_status } = this.state
        const can_register = customer_search_form_values &&
                             customer_search_form_values.armband_number &&
                             (ticket.id || ticket_nr)
        
        return (
            <MainLayout>
              <div css={main}>
                <CardRoundedBottom extra_class={card}>
                  <CardHeaderDark label="Register new guest" />
                  <div css={body}>
                    <CustomerScanForm is_registration_screen={true} />
                    { ticket_nr && ! is_loading && ! ticket.id &&
                    <div css={loader}>
                      <CardTextMedium label="Ticket not found" />
                    </div>
                    }
                    { ticket_nr && is_loading &&
                    <div css={loader}>
                      <CardTextMedium label="Searching for ticket ..." />
                    </div>
                    }

                    { registration_status === 'registering' &&
                    <div css={loader}>
                      <CardTextMedium label="Registering ticket ..." />
                    </div>
                    }

                    { ticket_nr && ticket.id && ! is_loading &&
                    <div css={loader}>
                      <CardTextMedium label="Please confirm your ticket details" />
                    </div>
                    }

                    { (ticket_nr && (!ticket.id || !is_loading)) &&
                    <CustomerRegistrationForm
                        onSubmit={this.handleSubmit}
                        onClickCancel={this.onClickCancel}
                        can_register={can_register}
                        initialValues={initial_form_values}/>
                    }
                  </div>
                </CardRoundedBottom>
              </div>
            </MainLayout>
        )
    }
}

function mapStateToProps(state) {

    const list_key = LIST_KEY__TICKET
    const visible_items = getVisibleItems(state, list_key, ENTITY_KEY__TICKET)
    const is_loading = isLoading(state, list_key)
    const filter = getListFilter(state, list_key)
    const ticket_nr = (filter && filter.ticket_nr) || null
    const ticket = size(visible_items) > 0 && visible_items[0]
    const found_match = ticket.ticket_nr === ticket_nr
    
    return {
        is_loading: ticket_nr && is_loading,
        list_key,
        ticket_nr,
        ticket,
        found_match,
        customer_search_form_values: getFormValues('CUSTOMER_SCAN_FORM')(state)
    }
}

export default withRouter(connect(mapStateToProps)(CustomerRegistration))

const main = css`
  display: flex; 
 flex: 1;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`

const card = css`
display: flex;
flex: 1;
flex-direction: column;
`

const body = css`
padding: 0 40px 40px 40px;
`

const loader = css`
 height: 100px;
 display: flex;
 align-items: center;
 justify-content: center;
 text-align: center;
`
