/** @jsx jsx */
import { Component } from 'react'
import ReactDatetime from 'react-datetime'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { jsx, css } from '@emotion/core'

import calendar_icon from '../images/calendar_icon.png'
import { default_theme as theme } from '../emotion/theme'

export default class DatetimePickerField extends Component {
    onChange = (value) => {
        const { input: { onChange } } = this.props
        if (!isEmpty(value) && value.format) {
            onChange(value.format(this.getDateTimeFormat()))
        } else {
            onChange(null)
        }
    }

    getDateTimeFormat() {
        return null
    }

    onInputChange = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    renderInput = (props, openCalendar) => {
        const { extra_class } = this.props;
        
        return (
              <input onClick={ openCalendar } {...props} css={ [date_field_styles,  extra_class] } />
        )
    }

    render() {
        const { input, input_placeholder, extra_container_class, date_format } = this.props
        let value;

        if (input.value) {
            value=moment(input.value).toDate()
        }

        return (
            <div css={[date_field, extra_container_class]}>
              <div css={date_field_wrapper}>
                <ReactDatetime
                    inputProps={{ placeholder: input_placeholder }}
                    closeOnSelect={true}
                    onChange={this.onChange}
                    value={value}
                    timeFormat={false}
                    dateFormat={date_format}
                    renderInput={ this.renderInput }
                />
              </div>
              <img src={calendar_icon} alt="" />
            </div>  
        )
    }
}

const date_field = css`
  border: 1px solid ${theme.colors.border_gray};
  background-color: ${theme.colors.white};
  border-radius: 5px;
 height: 40px;
 display: flex;
 align-items: center;
 padding: 0 8px 0 8px;
 width: 100%;
 
 &:focus, &:active {
   border: 1px solid ${theme.colors.gray4};
 }
 
 img {
    width: 25px;
     display: block;
   }
`

const date_field_styles = css`
 height: 38px;
 font-size: 17px;
 outline: none;
 color: ${theme.colors.text_gray_dark};
 font-weight: 500;
 display: flex;
 align-items: center;
 border: none;
    width: 100%;
`

const date_field_wrapper = css`
 outline: none;
 flex: 1;
`
