/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form';

import CardPrimaryButton from './CardPrimaryButton'
import CardSecondaryButton from './CardSecondaryButton'
import InputField from './InputField'
import DatePickerField from './DatePickerField'
import { default_theme as theme } from '../emotion/theme'
import 'react-datetime/css/react-datetime.css';
import { VISIT_STATUS_OPTIONS } from '../actions/ItemListKeyRegistry'

const required = value => (value || typeof value === 'number' ? undefined : 'Required')

class CustomerRegistrationForm extends Component {

    render() {
        const { handleSubmit, error, valid, can_register } = this.props

        const can_continue = valid && can_register
        
        return (
            <form css={form_layout} onSubmit={handleSubmit}>
                { false && 
              <div css={title}>
                <p>Please confirm your details are correct:</p>
                  </div>
                }
              <div css={body}>
                <div css={column}>
                  <span css={column_header}>Guest details:</span>
                  <div css={ cell }>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="child_name"
                        placeholder="Name"
                        type="text"
                        validate={[required]}
                        extra_class={form_input}/>
                  </div>
                  <div css={ cell }>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="child_surname"
                        placeholder="Surname"
                        type="text"
                        validate={[required]}
                        extra_class={form_input}/>
                  </div>
                  <div css={ cell }>
                    <Field
                        component={ DatePickerField }
                        name="child_birth_date"
                        type="text"
                        validate={[required]}
                        input_placeholder="Date of birth"
                        date_format={"DD / MM / YYYY"}
                        extra_class={date_field}
                        extra_container_class={date_field_container} />
                  </div>
                </div>

                <div css={column}>
                  <span css={column_header}>Guardian details:</span>
                  <div css={ cell }>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="guardian_name"
                        placeholder="Name"
                        type="text"
                        validate={[required]}
                        extra_class={form_input} />
                  </div>
                  <div css={ cell }>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="guardian_surname"
                        placeholder="Surname"
                        type="text"
                        extra_class={form_input}/>
                  </div>
                  <div css={ cell }>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="guardian_email_address"
                        placeholder="Email Address"
                        type="text"
                        extra_class={form_input}/>
                  </div>
                </div>

                <div css={[column, column_last]}>
                  <span css={column_header}>Collection details:</span>
                  <div css={ cell }>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="collector_name"
                        placeholder="Name"
                        type="text"
                        extra_class={form_input}/>
                  </div>
                  <div css={ cell }>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="collector_surname"
                        placeholder="Surname"
                        type="text"
                        extra_class={form_input} />
                  </div>
                  <div css={ cell }>
                    <Field
                        autoComplete="off"
                        component={ InputField }
                        name="collector_contact_number_1"
                        placeholder="Contact Number"
                        type="text"
                        extra_class={form_input}/>
                  </div>
                </div>
              </div>
              <div css={ error_container }>
                { error && <p>{ error } Invalid credentional</p>}
              </div>
              <div css={footer}>
                <CardPrimaryButton
                    type="button"
                    label="Cancel"
                    to="/home"
                    custom_styles={extra_btn_styles}
                /> 

                { can_continue && 
                  <CardSecondaryButton
                      type="sumbit"
                      label="Continue"
                      custom_styles={extra_btn_styles}/>
                }
                { !can_continue &&
                  <CardSecondaryButton
                      type="button"
                      label="Continue"
                      disabled={true}
                      custom_styles={extra_btn_styles}/>
                }
              </div>
            </form>
        )
    }
}
CustomerRegistrationForm = reduxForm({
    
    form: 'CUSTOMER_REGISTER_FORM',
    enableReinitialize: true,
    
})(CustomerRegistrationForm);

function mapStateToProps(state) {
    return {
        status_options: VISIT_STATUS_OPTIONS
    }
}

export default connect(mapStateToProps)(CustomerRegistrationForm)

const form_layout = css`
margin: 0; 
padding: 0;
`

const title = css`
margin-bottom: 40px;

   p {
    margin: 0;
    padding: 0;
       color: ${theme.colors.text_gray_dark};
       font-size: 19px;
   }
`

const cell = css`
margin-bottom: 12px;
`

const column_header = css`
font-size: 14px;
color: ${theme.colors.text_gray};
display: block;
margin-bottom: 10px;
font-weight: 500;
`

const body = css`

display: flex;
`

const footer = css`
display: flex;
justify-content: space-between;
`


const column = css`
flex: 1;
margin-right: 50px;
`

const column_last = css`
margin-right: 0;
`

const date_field_container = css`
  height: 48px;
`

const date_field = css`
height: 46px;
font-weight: 500;
color: ${theme.colors.text_gray_dark2};
font-size: 18px;
`

const form_input = css`
height: 48px;
font-weight: 500;
color: ${theme.colors.text_gray_dark2};
font-size: 18px;
`

const error_container = css`
display: flex;
justify-content: flex-start;
align-items: center;
height: 28px;

p {
    margin: 0;
    padding: 0;
    color: ${theme.colors.error};
}
`

const extra_btn_styles = {
    width: '200px',
    padding: '17px',
    fontSize: '18px',
    height: '55px',
    marginRight: '10px',
    fontWeight: 400,
    textAlign: 'center'
}
