/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'

// import {getCustomerFilterObject} from '../actions/Customer'
import CardRoundedBottom from './CardRoundedBottom'
import CardHeaderDark from './CardHeaderDark'
import CustomerSearchFilterForm from '../components/CustomerSearchFilterForm'
import moment from 'moment'
import { updateListFilter, clear_list_filter_option } from '../actions/ItemList'
import { forEach, pickBy, identity, isEmpty } from 'lodash'

class CustomerSearch extends Component {
    
    handleSubmit = (values) => {
        const { dispatch, list_key, fields } = this.props
        forEach(fields, function(k) {
            dispatch(clear_list_filter_option(list_key, k))
        })

        // Setting the end date to the following day if it is left blank
        // May need to modify via values.booked_from_date as well
        if(!isEmpty(values[fields[1]]) && isEmpty(values[fields[2]])){
            const value = moment(values[fields[1]]);
            value.add(1, 'd');
            values[fields[2]] = value.toISOString();
        }
        if(!isEmpty(values[fields[3]]) && isEmpty(values[fields[4]])){
            const value = moment(values[fields[3]]);
            value.add(1, 'd');
            values[fields[4]] = value.toISOString();
        }
        if(!isEmpty(values[fields[5]]) && isEmpty(values[fields[6]])){
            const value = moment(values[fields[5]]);
            value.add(1, 'd');
            values[fields[6]] = value.toISOString();
        }

        dispatch(updateListFilter(list_key, pickBy(values, identity)))
    }
    
    render() {       
        return (
            <CardRoundedBottom extra_class={main}>
              <CardHeaderDark label="Visitor search" />

              <div css={form_container}>
                <CustomerSearchFilterForm onSubmit={ this.handleSubmit } />
              </div>
            </CardRoundedBottom>
        )
    }
}
function mapStateToProps(state, props) {
    const { list_key } = props
    const fields = ['any_field',
                    'booked_date_from',
                    'booked_date_to',
                    'registered_date_from',
                    'registered_date_to',
                    'sign_out_date_from',
                    'sign_out_date_to']
    return {
        list_key,
        fields
    }
}
export default connect(mapStateToProps)(CustomerSearch)

const main = css`
margin-right: 100px;
width: 450px;
height: 644px;
`

const form_container = css`
 padding: 40px;
`
