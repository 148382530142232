/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'

import LoginForm from './LoginForm'
import { login, isLoggedIn, isLoading } from '../actions/Auth'
import { default_theme as theme } from '../emotion/theme'
import logo from '../images/logo_large.png'

class Login extends Component {

    handleSubmit = (values) => {
        const {dispatch} = this.props
        return dispatch(login(values.email, values.password))
    }

    componentDidUpdate(prevProps) {
        const { isLoggedIn, history } = this.props
        if (!prevProps.isLoggedIn && isLoggedIn) {
            history.push('/home')
        }
    }

    render() {
        const { isLoading } = this.props
        return (
            <div css={ main }>
              <div css={container}>
                <div css={header}>
                  <img src={logo} alt="" />
                </div>
                <LoginForm
                    isLoading={ isLoading }
                    onSubmit={ this.handleSubmit } />
              </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        isLoggedIn: isLoggedIn(state),
        isLoading: isLoading(state)
    }
}
export default withRouter(connect(mapStateToProps)(Login))

const main = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: ${theme.colors.white};
min-height: 100vh;
padding: 0 16px;
`

const container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
max-width: 520px;
width: 100%;
`

const header = css`
  img {
    width: 100%;
    max-width: 520px;
  }
`
