import { get } from 'lodash'

export const LOADING = "LOADING"

export function loadingOn() {
    return {
        type: LOADING,
        status: true
    }
}

export function loadingOff() {
    return {
        type: LOADING,
        status: false
    }
}

export function isLoading(state) {
    return get(state, ["loading", "status"], false)
}
